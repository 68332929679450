import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import HeaderMission from '../../Components/HeaderMission';
import { FaChevronLeft, FaSave } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Select,
} from '@chakra-ui/react';

const UpdateMission = () => {
  const { id } = useParams();
  const location = useLocation();
  const [state, setState] = useState(location.state);
  console.log(state);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const data: { [key: string]: any } = {};
    for (let [name, value] of formData.entries()) {
      data[name] = value;
    }
    console.log(data);
  };

  const handleAddSprint = () => {
    setState((prevState: any) => ({
      ...prevState,
      sprints: [
        ...prevState.sprints,
        {
          id: state.sprints.length + 1,
          name: '',
          sprintElements: [
            {
              id: 1,
              description: '',
              price_ht: '',
              price_ttc: '',
              quantity: '',
            },
          ],
        },
      ],
    }));
  };

  const handleAddSprintElement = (sprintIndex: any) => {
    setState((prevState: any) => {
      const sprints = [...prevState.sprints];
      const sprint = { ...sprints[sprintIndex] };
      sprint.sprintElements.push({
        id: sprint.sprintElements.length + 1,
        description: '',
        price_ht: '',
        price_ttc: '',
        quantity: '',
      });
      sprints[sprintIndex] = sprint;
      return { ...prevState, sprints };
    });
  };

  return (
    <>
      <HeaderMission text="Update mission" />

      <Link to={`/mission/${id}`}>
        <button className="flex flex-row p-3 bg-primary rounded-lg transition duration-150 ease-in hover:scale-105 text-white mt-5 ml-5">
          <FaChevronLeft className="mr-4 mt-1" />
          Retour à la mission
        </button>
      </Link>

      <Flex className="m-5 w-fit">
        <form onSubmit={handleSubmit}>
          <FormControl padding={2}>
            <div className="bg-primary justify-center text-white p-2 rounded-xl bg-gradient">
              <FormLabel>Nom de la mission</FormLabel>
              <Input
                type="text"
                defaultValue={state.name}
                name="missionName"
                variant={'flushed'}
              />
            </div>
            <Flex
              flexDirection="column"
              className="space-y-3 mt-5 rounded-xl p-3 bg-primary text-white bg-gradient"
            >
              {state.sprints.map((sprint: any, sprintIndex: number) => (
                <Box key={sprint.id}>
                  <FormLabel>Nom du sprint</FormLabel>
                  <Input
                    type="text"
                    defaultValue={sprint.name}
                    name={`sprintName_${sprintIndex}`}
                    variant={'flushed'}
                  />
                  {sprint.sprintElements.map(
                    (element: any, elementIndex: number) => (
                      <Box
                        className="flex flex-row mt-3 space-y-5 space-x-5"
                        key={element.id}
                      >
                        <FormLabel marginTop={5}>Description</FormLabel>
                        <Input
                          type="text"
                          defaultValue={element.description}
                          name={`elementDescription_${sprintIndex}_${elementIndex}`}
                          variant={'flushed'}
                        />
                        <FormLabel>Prix HT</FormLabel>
                        <Input
                          type="text"
                          defaultValue={element.price_ht}
                          name={`elementPriceHt_${sprintIndex}_${elementIndex}`}
                          variant={'flushed'}
                        />
                        <FormLabel>Prix TTC</FormLabel>
                        <Input
                          type="text"
                          defaultValue={element.price_ttc}
                          name={`elementPriceTtc_${sprintIndex}_${elementIndex}`}
                          variant={'flushed'}
                        />
                        <FormLabel>Quantité</FormLabel>
                        <Input
                          type="text"
                          defaultValue={element.quantity}
                          name={`elementQuantity_${sprintIndex}_${elementIndex}`}
                          variant={'flushed'}
                        />
                      </Box>
                    )
                  )}
                  <button
                    type="button"
                    className="w-full"
                    onClick={() => handleAddSprintElement(sprintIndex)}
                  >
                    Add a sprint element
                  </button>
                  <div className="h-0.5 rounded-md bg-black mx-3 mt-2"></div>
                </Box>
              ))}
              <button type="button" onClick={handleAddSprint}>
                Add a sprint
              </button>
            </Flex>
          </FormControl>

          <button
            type="submit"
            className="flex w-fit p-3 bg-primary rounded-lg transition duration-150 ease-in hover:scale-105 mt-5 text-white"
          >
            <FaSave className="mr-4 mt-1" />
            Sauvegarder
          </button>
        </form>
      </Flex>
    </>
  );
};

export default UpdateMission;

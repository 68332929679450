import React, { useState } from 'react';
import { IoIosSend } from 'react-icons/io';

export const ChatbotInput = ({ addMessage }: ChatbotInputProps) => {
  const [content, setContent] = useState('');

  const onSubmit = (e: any) => {
    e.preventDefault();
    addMessage('user', content);
    setContent('');
  };

  return (
    <div className="h-16 p-2 absolute bottom-0 w-full bg-creamWhite rounded-b-md">
      <form
        onSubmit={onSubmit}
        className="flex justify-center items-center gap-2"
      >
        <input
          type="text"
          className="w-full outline-none rounded-md p-2 shadow-md"
          placeholder="Votre message..."
          value={content}
          onChange={(e) => {
            setContent(e.target.value);
          }}
        />
        <button
          type="submit"
          className="bg-gradient hover:shadow-md transition-all duration-200 p-2 rounded-full"
        >
          <IoIosSend size={24} color="white" />
        </button>
      </form>
    </div>
  );
};

export interface ChatbotInputProps {
  addMessage: any;
}

export default ChatbotInput;

import React, { useState, startTransition, Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { motion } from 'framer-motion';
import Spline from '@splinetool/react-spline';
// import "../i18n"
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../Components/LanguageSwitcher';
import '../styles.css';

// Images
import Wave from '../assets/wave.png';

// Icons
import { BsChatDots } from 'react-icons/bs';
import { GiWhistle } from 'react-icons/gi';
import {
  AiOutlinePlus,
  AiOutlineCheckCircle,
  AiOutlineFileSearch,
} from 'react-icons/ai';

// Type
import { IconType } from 'react-icons';

// Hooks
import { InView } from 'react-intersection-observer';

// Components
import Hero from '../Components/Landing/Hero';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import BenefitSection from '../Components/Landing/BenefitSection';
import PricingSection from '../Components/Landing/PricingSection';

type InfoDropdownProps = {
  title: string;
  details: string;
};

type LitigeDetailsProps = {
  index: number;
  title: string;
  Icon: IconType;
  description: string;
};

const SuspenseTrigger = () => {
  throw new Promise(() => {});
};

const handleScroll = () => {
  const splineScene = document.getElementById('spline-scene');

  if (splineScene !== null) {
    const isMouseOverSpline = splineScene.matches(':hover');

    if (isMouseOverSpline) {
      splineScene.style.pointerEvents = 'auto';
      console.log('mouse over spline');
    } else {
      splineScene.style.pointerEvents = 'none';
      console.log('mouse not over');
    }
  }
};

const InfoDropdown = ({ title, details }: InfoDropdownProps) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="flex justify-start items-/Users/matheorobert/Desktop/epi/Luter-front/Luter-smart-contract gap-4">
      <div className="flex flex-col justify-start items-start gap-2 w-full">
        <div className="flex justify-between items-center">
          <h1 className="text-white font-bold">{title}</h1>
          <button
            className="flex justify-center items-center bg-primary rounded-full min-w-[40px] min-h-[40px]"
            onClick={() => setOpen(!open)}
          >
            <span
              className={`transition-all duration-300 transform ${
                open ? 'rotate-45' : 'rotate-0'
              }`}
            >
              <AiOutlinePlus size={20} color="#fff" />
            </span>
          </button>
        </div>
        {open && <p className="text-white font-light">{details}</p>}
        <hr className="border-[#fff] border-opacity-70 w-full" />
      </div>
    </div>
  );
};

const LitigeDetails = ({
  index,
  title,
  Icon,
  description,
}: LitigeDetailsProps) => {
  return (
    <div className="flex flex-col justify-around gap-8">
      <div className="flex justify-between gap-4">
        <span className="flex justify-start items-center">
          <p className="text-white bg-primary rounded-xl w-10 py-1 flex justify-center items-center font-bold text-2xl">
            {index}
          </p>
          <h1 className="font-bold ml-4">{title}</h1>
        </span>
        <Icon size={30} color="#1552F0" />
      </div>
      <p>{description}</p>
      <hr className="border-black border-opacity-70 w-full" />
    </div>
  );
};

function App() {
  PricingSection;
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div>
      {/* <LanguageSwitcher /> */}
      <Navbar isLogged={true} isFixed={false} />
      <div className="relative min-h-[90vh] w-full bg-gradient-to-r from-[#1552F0] to-[#16A0F0] flex justify-between items-center h-full z-10">
        <div className="flex flex-col gap-6 md:gap-0 md:flex-row justify-between items-center w-full h-full px-8">
          <Hero />
        </div>
        <img
          src={Wave}
          alt="wave"
          className="absolute bottom-0 right-0 w-full"
        />
      </div>
      <div className="relative z-10 mb-10">
        <div className="absolute z-0 left-[3.75rem] top-[-5vh] md:top-[-170px] bg-primary  w-[3px] lg:w-[4px] h-[20vh] md:h-[200px] flex justify-center items-center"></div>
        <BenefitSection />
        <PricingSection />

        <section className="bg-primary flex md:flex-row gap-6 items-center flex-col justify-around text-white p-6 my-10">
          <div className="flex flex-col justify-center items-start md:w-1/2">
            <h1 className="font-bold text-4xl mb-4">
              {t('commitments.title')}
            </h1>
            <p>{t('commitments.description')}</p>
          </div>
          <div className="w-full md:w-fit">
            <InfoDropdown
              title="Sécurité pour vous et votre client"
              details="Nous nous engageons à sécuriser vos données et vos fonds. Nous utilisons les dernières technologies de cryptage et de protection des données pour vous assurer une sécurité optimale."
            />
            <InfoDropdown
              title="Accompagnement personnalisé"
              details="Nous nous engageons à vous accompagner dans votre utilisation de Luter. Notre équipe est à votre disposition pour répondre à vos questions et vous aider à utiliser la plateforme."
            />
            <InfoDropdown
              title="Plateforme innovante"
              details="Nous nous engageons à vous offrir une plateforme optimisée pour vous permettre de travailler dans les meilleures conditions. Nous travaillons chaque jour pour améliorer votre expérience."
            />
            <InfoDropdown
              title="Éthique et transparence"
              details="Nous nous engageons à vous offrir une plateforme éthique. Nous ne vendons pas vos données et nous ne les utilisons pas à des fins commerciales."
            />
          </div>
        </section>
        <section className="mx-6">
          <h1 className="font-bold text-4xl mb-4 text-primary text-center">
            Et en cas de litige ?
          </h1>
          {/* <p className="text-center text-md text-gray-500 mb-10">{t('dispute_process_description')}</p> */}
          <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-4 p-6">
            <LitigeDetails
              index={1}
              Icon={AiOutlineFileSearch}
              title="Évaluation"
              description="Lorsqu'un litige est déclaré, notre équipe d'assistance technique examine les preuves soumises par les deux parties pour évaluer la situation."
            />
            <LitigeDetails
              index={2}
              Icon={BsChatDots}
              title="Médiation"
              description="Si le litige ne peut pas être résolu par notre équipe d'évaluation, nous avons des médiateurs disponibles pour aider à négocier un règlement équitable pour les deux parties."
            />
            <LitigeDetails
              index={3}
              Icon={GiWhistle}
              title="Arbitrage"
              description="Si la médiation échoue, nous proposons une option d'arbitrage pour les clients et les freelances. Cette étape est plus formelle et implique un tiers indépendant qui évalue les preuves et rend une décision."
            />
            <LitigeDetails
              index={4}
              Icon={AiOutlineCheckCircle}
              title="Résolution"
              description="Une fois qu'une décision a été prise, nous aidons les parties à mettre en œuvre la décision et à résoudre le litige de manière satisfaisante."
            />
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
}

export default App;

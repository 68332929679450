import React, { useState } from 'react';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import InfoMission from '../Components/GuidanceForm/InfoMission';
import InfoClient from '../Components/GuidanceForm/InfoClient';
import InfoFreelance from '../Components/GuidanceForm/InfoFreelance';
import FormResults from '../Components/GuidanceForm/FormResults';

const GuidanceForm = () => {
  const [currentStep, setCurrentStep] = useState(1);

  const goToNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const goToPreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const renderFormStep = (step: number) => {
    console.log(currentStep);
    switch (step) {
      case 1:
        return <InfoMission onNext={goToNextStep}/>;
      case 2:
        return (
          <InfoFreelance
            onPrev={goToPreviousStep}
            onNext={goToNextStep}
          />
        );
      case 3:
        return (
          <InfoClient onPrev={goToPreviousStep} onNext={goToNextStep} />
        );
      case 4:
        return <FormResults />;
      default:
        return null;
    }
  };

  return (
    <div>
      <Navbar isLogged={false} />
      {renderFormStep(currentStep)}
      <Footer />
    </div>
  );
};

export default GuidanceForm;

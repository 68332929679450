import React, { useState } from 'react';
import ApiCalendar from 'react-google-calendar-api';
import { useSelector, useDispatch } from 'react-redux';
import { connectGoogle } from '../../redux/feature/userSlice';
import Button from '../Button';
import displaySuccessToast from '../../utils/displaySuccessToast';

const config = {
  clientId: import.meta.env.VITE_GOOGLE_CLIENT_ID,
  apiKey: import.meta.env.VITE_GOOGLE_API_KEY,
  scope: 'https://www.googleapis.com/auth/calendar',
  discoveryDocs: [
    'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
  ],
};

const apiCalendar = new ApiCalendar(config);

export const GoogleCalendarConnectButton = ({
  purpose,
  event,
}: GoogleCalendarConnectButtonProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const isConnect = useSelector((state: any) => state.user.isGoogleLogged);
  const handleItemClick = async (e: any, name: string, event?: any) => {
    if (name === 'sign-in') {
      apiCalendar.handleAuthClick();
      dispatch(connectGoogle(true));
    } else if (name === 'sign-out') {
      await apiCalendar.handleSignoutClick();
      dispatch(connectGoogle(false));
    } else if (name === 'sync') {
      setIsLoading(true);
      apiCalendar.onLoad(() => {
        if (apiCalendar.sign) {
          const eventToCreate = {
            summary: event.title,
            location: event.location,
            description: event.description,
            start: {
              dateTime: event.start,
              timeZone: 'Europe/Paris',
            },
            end: {
              dateTime: event.end,
              timeZone: 'Europe/Paris',
            },
          };
          apiCalendar
            .createEvent(eventToCreate)
            .then((result: object) => {
              setIsLoading(false);
              displaySuccessToast(
                'Votre évènement a bien été synchronisé avec votre calendrier Google'
              );
              console.log(result);
            })
            .catch((error: any) => {
              setIsLoading(false);
              console.log(error);
            });
        }
      });
    }
  };

  return (
    <div className="border p-4 rounded-lg flex flex-col">
      Synchroniser avec mon calendrier Google
      {isConnect ? (
        <>
          {purpose === 'disconnect' && (
            <Button
              onClick={(e: any) => handleItemClick(e, 'sign-out')}
              label="Déconnexion Google"
              primary
            />
          )}
          {purpose === 'synchronize' && (
            <Button
              loading={isLoading}
              onClick={(e: any) => handleItemClick(e, 'sync', event)}
              label="Synchroniser avec mon calendrier Google"
              primary
            />
          )}
        </>
      ) : (
        <Button
          onClick={(e: any) => handleItemClick(e, 'sign-in')}
          label="Connexion Google"
          primary
        />
      )}
    </div>
  );
};

type EventType = {
  title: string;
  location: string;
  description: string;
  start: string;
  end: string;
};

interface GoogleCalendarConnectButtonProps {
  purpose: 'synchronize' | 'disconnect';
  event?: EventType;
}

export default GoogleCalendarConnectButton;

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateConfidence,
  updateMissions,
  updateTechno,
} from '../../redux/feature/formSlice';
import { FormStateType } from '../../redux/feature/formSlice';
import FormulaCalculator from './FormulaCalculator';
import PopUp from '../PopUp';
import ProgressBar from '../ProgressBar';
import FeelingInput from './FeelingInput';
import BulleForm from '../BulleForm';

function InfoFreelance({ onNext, onPrev }: any) {
  const dispatch = useDispatch();
  const {
    price,
    duration,
    tarificationHonnete,
    delaisCohérents,
    confiance,
    missions,
    techno,
    clientTravail,
    clientParticulier,
    clientLuter,
  } = useSelector((state: any) => state.form) as FormStateType;
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const isNumberValid = (value: string) => {
    // Utilisez une expression régulière pour vérifier si la valeur est un nombre valide
    const numberPattern = /^[0-9]+$/;
    return numberPattern.test(value);
  };

  // Gestionnaires d'événements pour mettre à jour l'état Redux à chaque changement

  const handleMissionsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateMissions(e.target.value));
  };

  const handleTechnoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateTechno(e.target.value));
  };

  const handleNext = () => {
    if (techno && missions) {
      onNext();
    } else {
      setShowPopup(true);
    }
  };
  const handleClosePopup = () => {
    setShowPopup(false);
  };
  const handleConfirmPopup = () => {
    console.log('Popup confirmé!');
  };
  const handleCancelPopup = () => {
    console.log('Popup annulé!');
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="flex flex-col items-center justify-center w-full h-screen overflow-hidden">
      {showPopup && (
        <PopUp
          title="Champs manquants"
          text="Veuillez répondre à toutes les questions pour continuer"
          onConfirm={handleConfirmPopup}
          onCancel={handleCancelPopup}
          onClose={handleClosePopup}
          style={{ zIndex: 999 }}
        />
      )}
      <div
        className="p-2 w-full flex flex-row p-30"
        style={{ height: '100%', padding: 30, paddingTop: '110px' }}
      >
        <div className="w-full md:w-3/5 pr-4 relative flex flex-col">
          <ProgressBar percent={66} className="sticky top-0 z-20" />
          <h1 className="mt-10 ml-10 text-4xl font-bold">
            Informations sur le freelance
          </h1>
          <div className="flex flex-col gap-6 pt-10 justify-center items-center h-full">
            <div className="w-full md:w-3/5 pr-4 justify-center relative flex flex-col">
              {/* PARTIE CONFIANCE */}
              <div className="flex flex-col gap-6">
                <div className="flex flex-col md:flex-row gap-3">
                  <div className="flex-row mr-10">
                    <p className="text-1xl font-light mb-2">
                      Votre confiance sur la mission ?
                    </p>
                    <FeelingInput />
                  </div>
                  {/* PARTIE NB MISSION */}
                  <label className="flex flex-col text-1xl font-light">
                    Nombre de missions réalisées
                    <div className="flex items-center text-2xl font-bold">
                      <input
                        type="text"
                        value={missions}
                        onChange={handleMissionsChange}
                        className="w-full md:w-60 text-center border border-black border-2 rounded-l-md p-2"
                      />
                      <span className="bg-blue-500 text-white border-black border-2 p-2 rounded-r-md -ml-4">
                        J/H
                      </span>
                      {isNumberValid(missions) ? (
                        <span className="text-green-700 p-2 rounded-full ml-2">
                          ✓
                        </span>
                      ) : (
                        <span className="text-red-700 p-2 rounded-full ml-2">
                          ✗
                        </span>
                      )}
                    </div>
                  </label>
                </div>
              </div>

              <div className="flex flex-row gap-5 mt-10">
                {/* PARTIE TARIFICATION */}
                <div className="flex flex-col gap-3">
                  <p className="text-1xl font-light">
                    Utilisez-vous vos technologies habituelles ?
                  </p>
                  <div className="flex flex-row gap-3">
                    <label className="bg-gray-50 border-2 border-black p-2 rounded-md text-xl block sm:w-1/2 lg:w-2/5 p-2.5">
                      <div className="flex items-center">
                        <input
                          type="radio"
                          value="Oui"
                          checked={techno === 'Oui'}
                          onChange={handleTechnoChange}
                          className="border border-gray-300 rounded-md p-2"
                        />
                        <span className="ml-2">Oui</span>
                      </div>
                    </label>
                    <label className="bg-gray-50 border-2 border-black p-2 rounded-md text-xl block sm:w-1/2 lg:w-2/5 p-2.5">
                      <div className="flex items-center">
                        <input
                          type="radio"
                          value="Non"
                          checked={techno === 'Non'}
                          onChange={handleTechnoChange}
                          className="border border-gray-300 rounded-md p-2"
                        />
                        <span className="ml-2">Non</span>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <div className="mt-20 text-right flex items-center justify-between">
                <button
                  onClick={onPrev}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Précédent
                </button>
                <button
                  onClick={handleNext}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Suivant
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-10"></div>
        {windowWidth > 1280 && (
          <div className="flex flex-col h-full">
            <h2 className="mt-20 ml-10 text-2xl text-center font-bold">
              Formule en temps réel
            </h2>
            <div className="flex-grow mt-10">
              <FormulaCalculator
                price={price}
                duration={duration}
                tarificationHonnete={tarificationHonnete}
                delaisCohérents={delaisCohérents}
                confiance={confiance}
                missions={missions}
                techno={techno}
                clientTravail={clientTravail}
                clientParticulier={clientParticulier}
                clientLuter={clientLuter}
              />
            </div>
            <div className="flex-none mt-auto"></div>
          </div>
        )}
      </div>
    </div>
  );
}

export default InfoFreelance;

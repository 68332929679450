import React, { useEffect, useState } from 'react';
import PricingCard from '../Landing/PricingCard';
import { BsPen } from 'react-icons/bs';
import { AiOutlineSafetyCertificate } from 'react-icons/ai';
import { SlNotebook } from 'react-icons/sl';
import { VscLaw } from 'react-icons/vsc';
import { MdOutlineRemoveRedEye } from 'react-icons/md';

interface FormulaCalculatorProps {
  price: string;
  duration: string;
  tarificationHonnete: string;
  delaisCohérents: string;
  confiance: string;
  missions: string;
  techno: string;
  clientTravail: string;
  clientParticulier: string;
  clientLuter: string;
}

const FormulaCalculator: React.FC<FormulaCalculatorProps> = ({
  price,
  duration,
  tarificationHonnete,
  delaisCohérents,
  confiance,
  missions,
  techno,
  clientTravail,
  clientParticulier,
  clientLuter
}) => {
  const [bestFormula, setBestFormula] = useState<string>('');
  const handleCTAClick = () => {
    // Gérer le clic sur le bouton CTA
  };
  const determineBestFormula = () => {
    if (parseInt(price) < 5000 && parseInt(duration) < 30 && tarificationHonnete === 'Oui' && delaisCohérents === 'Oui') {
      return 'Green';
    } else if (parseInt(duration) > 30 || parseInt(price) > 5000) {
      return 'Bleue';
    } else if (tarificationHonnete === 'Non' || delaisCohérents === 'Non' || techno === 'Non') {
      return 'Purple';
    } else {
      return 'Autre';
    }
  };

  // Définir les paramètres pour chaque couleur
  const colorParams: Record<string, any> = {
    Green: {
      title: 'Green',
      description: 'Une formule d\'entrée de gamme.',
      price: '2%',
      offre: '1 mission offerte en optant pour l\'offre annuelle',
      advantages: [
        { text: 'Génération de smart contracts', icon: BsPen },
        { text: 'Génération du cahier des charges', icon: SlNotebook },
        { text: 'Séquestration de fonds', icon: AiOutlineSafetyCertificate },
      ],
      ctaLabel: 'Essayer Luter Green',
      color: '#1A4731',
    },
    Bleue: {
      title: "Blue",
      description: "Une formule adaptée aux besoins habituels.",
      price: "7%",
      offre: "1 mission offerte en optant pour l'offre annuelle",
      advantages: [
        { text: 'Génération de smart contracts', icon: BsPen },
        { text: 'Génération du cahier des charges', icon: SlNotebook },
        { text: 'Séquestration de fonds', icon: AiOutlineSafetyCertificate },
        { text: 'Gestion litiges < 5k€', icon: VscLaw },
        { text: 'Validation cahier des charges', icon: MdOutlineRemoveRedEye },
      ],
      ctaLabel: "Essayer Luter Blue",
      color: '#194D81'
    },
    Purple: {
      title: "Purple",
      description: "Adaptée à certains besoins.",
      price: "11%",
      offre: "2 missions offertes en optant pour l'offre annuelle",
      advantages: [
        { text: 'Génération de smart contracts', icon: BsPen },
        { text: 'Génération du cahier des charges', icon: SlNotebook },
        { text: 'Séquestration de fonds', icon: AiOutlineSafetyCertificate },
        { text: 'Gestion litiges > 5k€', icon: VscLaw },
        { text: 'Validation cahier des charges', icon: MdOutlineRemoveRedEye },
      ],
      ctaLabel: "Essayer Luter Purple",
      color: '#563D7C'
    },
    Autre: {
      title: '?',
      description: '',
      price: '',
      offre: '',
      advantages: [
      ],
      ctaLabel: '',
      color: '#000000',
    },
  };

  useEffect(() => {
    console.log('price CALCUL : ', price);
    const newBestFormula = determineBestFormula();
    setBestFormula(newBestFormula);
  }, [price, duration, tarificationHonnete, delaisCohérents]);

  return (
    <div>
      {bestFormula && (
        <PricingCard
          title={colorParams[bestFormula].title}
          description={colorParams[bestFormula].description}
          price={colorParams[bestFormula].price}
          offre={colorParams[bestFormula].offre}
          advantages={colorParams[bestFormula].advantages}
          ctaLabel={colorParams[bestFormula].ctaLabel}
          ctaOnClick={handleCTAClick}
          color={colorParams[bestFormula].color}
        />
      )}
    </div>
  );
};

export default FormulaCalculator;

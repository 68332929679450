import React, { FormEvent, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import DashboardLayout from '../../Layout/DashboardLayout';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { useAddClientMutation } from '../../redux/services/clients';
import displaySuccessToast from '../../utils/displaySuccessToast';
import displayWarningToast from '../../utils/displayWarningToast';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useGetUserAutoLoginMutation } from '../../redux/services/auth';
import { autoLogin } from '../../redux/feature/userSlice';

const initialClient = {
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  siret: '',
  tva_number: '',
  company_name: '',
  address: '',
};

const AddClient = () => {
  const navigate = useNavigate();
  const user = useSelector((state: any) => state.user.user);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [addClient, response] = useAddClientMutation();
  const [isFormValid, setIsFormValid] = useState(false);
  const [editableClient, setEditableClient] = useState(initialClient);
  const [getUserAutoLogin] = useGetUserAutoLoginMutation();
  const handleInputChange = (fieldName: string, value: string) => {
    setEditableClient({
      ...editableClient,
      [fieldName]: value,
    });
    const isValid = Object.values(editableClient).every((val) => val !== '');
    setIsFormValid(isValid);
  };

  async function fetchUser() {
    console.log(searchParams.get('token'));
    let token = searchParams.get('token');
    const res: any = await getUserAutoLogin(token);
    dispatch(
      autoLogin({ user: res.data.user, accessToken: searchParams.get('token') })
    );
    console.log(res);
    return res.data.user;
  }

  const handleCreate = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    const updatedClientDetails = { ...editableClient };

    try {
      displayWarningToast('Création du client en cours', 'creatingClient');
      // regex check email function

      const checkEmail = (email: string) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
      };

      if (!checkEmail(updatedClientDetails.email)) {
        displayWarningToast('Email invalide', 'errorCreatingClient');
        return;
      }

      await addClient({ body: updatedClientDetails })
        .unwrap()
        .then((res: any) => {
          displaySuccessToast(
            'Client créé avec succès',
            'successCreatingClient'
          );
          navigate('/client');
        })
        .catch((err: any) => {
          console.log(err);
          displayWarningToast(
            'Erreur lors de la création du client',
            'errorCreatingClient'
          );
        });

      // const user = await fetchUser();
      // const updatedUser = {
      //   ...user,
      // };
      // dispatch(autoLogin(updatedUser));
    } catch (error) {
      displayWarningToast(
        'Erreur lors de la création du client',
        'errorCreatingClient'
      );
      console.error('Error adding client:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <DashboardLayout>
      <form onSubmit={handleCreate}>
        <div className="flex flex-col sm:flex-row justify-between items-center">
          <div className="text-4xl md:ml-8 text-mainBlack sm:ml-8 max-[640px]:text-center max-[640px]:my-4 max-[640px]:ml-8">
            Créer un client
          </div>
          <Link className="max-[640px]:ml-8" to="/client">
            <button className="flex bg-primary hover:bg-blue-600 text-white py-2 px-4 mb-4 mr-4 rounded ">
              <AiOutlineArrowLeft size={24} className="mr-2 text-white" />
              Retour
            </button>
          </Link>
        </div>
        <div className="h-2/3 bg-fullWhite mt-4 mt-8 p-8 rounded-[30px] border border-whiteGrey shadow-xl overflow-auto md:ml-8 sm:ml-8 max-[640px]:ml-8 text-xl">
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex' }} className="justify-between mb-4">
              <div className="w-full mr-2">
                <p className="text-primary">
                  <strong>Prénom:</strong>
                </p>
                <input
                  type="text"
                  value={editableClient.first_name}
                  className="text-mainBlack w-full rounded px-2 border-2 border-lightGrey bg-fullWhite"
                  onChange={(e) =>
                    handleInputChange('first_name', e.target.value)
                  }
                />
              </div>
              <div className="w-full ml-2">
                <p className="text-primary">
                  <strong>Nom:</strong>
                </p>
                <input
                  type="text"
                  value={editableClient.last_name}
                  className="text-mainBlack w-full rounded px-2 border-2 border-lightGrey bg-fullWhite"
                  onChange={(e) =>
                    handleInputChange('last_name', e.target.value)
                  }
                />
              </div>
            </div>
            <div className="mb-4">
              <p className="text-primary">
                <strong>Nom de l&apos;entreprise:</strong>
              </p>
              <input
                type="text"
                value={editableClient.company_name}
                className="text-mainBlack w-full rounded px-2 border-2 border-lightGrey bg-fullWhite"
                onChange={(e) =>
                  handleInputChange('company_name', e.target.value)
                }
              />
            </div>
            <div className="mb-4">
              <p className="text-primary">
                <strong>Adresse:</strong>
              </p>
              <input
                type="text"
                value={editableClient.address}
                className="text-mainBlack w-full rounded px-2 border-2 border-lightGrey bg-fullWhite"
                onChange={(e) => handleInputChange('address', e.target.value)}
              />
            </div>
            <div className="mb-4">
              <p className="text-primary w-full">
                <strong>Téléphone:</strong>
              </p>
              <input
                type="text"
                value={editableClient.phone}
                maxLength={13}
                className="text-mainBlack w-full rounded px-2 border-2 border-lightGrey bg-fullWhite"
                onChange={(e) => handleInputChange('phone', e.target.value)}
              />
            </div>
            <div className="mb-4">
              <p className="text-primary w-full">
                <strong>Email:</strong>
              </p>
              <input
                type="text"
                value={editableClient.email}
                className="text-mainBlack w-full rounded px-2 border-2 border-lightGrey bg-fullWhite"
                onChange={(e) => handleInputChange('email', e.target.value)}
              />
            </div>
            <div style={{ display: 'flex' }} className="justify-between">
              <div className="w-full mr-2">
                <p className="text-primary w-full">
                  <strong>SIRET:</strong>
                </p>
                <input
                  type="text"
                  value={editableClient.siret}
                  maxLength={14}
                  className="text-mainBlack w-full rounded px-2 border-2 border-lightGrey bg-fullWhite"
                  onChange={(e) => handleInputChange('siret', e.target.value)}
                />
              </div>
              <div className="w-full ml-2">
                <p className="text-primary w-full">
                  <strong>Numéro de TVA:</strong>
                </p>
                <input
                  type="text"
                  value={editableClient.tva_number}
                  maxLength={13}
                  className="text-mainBlack w-full rounded px-2 border-2 border-lightGrey bg-fullWhite"
                  onChange={(e) =>
                    handleInputChange('tva_number', e.target.value)
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-8 ml-8">
          <button
            type="submit"
            className={`flex bg-primary hover:bg-blue-600 text-white py-2 px-4 mt-4 mr-4 rounded ${
              (!isFormValid || isSubmitting) && 'opacity-50 cursor-not-allowed'
            }`}
            disabled={!isFormValid || isSubmitting}
          >
            {isSubmitting ? 'En cours de création...' : 'Créer'}
          </button>
        </div>
      </form>
    </DashboardLayout>
  );
};

export default AddClient;

/* {
    "id": 3,
    "first_name": "John",
    "last_name": "Doe",
    "email": "test@test.com",
    "phone": "+49 89 416115623",
    "siret": "12345678901234",
    "tva_number": "FR12345678901",
    "photo": null,
    "job": null,
    "city": null,
    "pseudo": null,
    "experience": null,
    "pseudoTwitter": null,
    "pseudoGithub": null,
    "pseudoLinkedin": null,
    "pseudoFacebook": null,
    "company_name": "John Doe Consulting",
    "customerId": null,
    "address": "123 Rue de la Paix, Paris, France",
    "user_type": "freelance",
    "reset_password_token": null,
    "connect_account": "acct_1OJmbMQ0UbhCOjWD",
    "checkout_token": null,
    "password": "8bdb85c0941bdfadb820318a7c17859740a0760d89a034bda3a5f4a96b742316",
    "TJM": 0,
    "job_position": null,
    "wallet_address": null,
    "company_logoKey": null,
    "company_logo": null
} */

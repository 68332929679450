import React, { Fragment } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Flex, Box, Table, Thead, Tr, Th, Td, Tbody } from '@chakra-ui/react';
import { Popover, Transition } from '@headlessui/react';
import HeaderMission from '../../Components/HeaderMission';
import SidebarMission from '../../Components/SidebarMission';
import { AiOutlineMenu, AiOutlineCheck } from 'react-icons/ai';
import { BsEmojiAngry, BsList } from 'react-icons/bs';

import { useToggleSprintElementDoneMutation } from '../../redux/services/missions';
import { useGetMissionByIdQuery } from '../../redux/services/missions';
import { useToggleFreelanceApprovalSprintMutation } from '../../redux/services/missions';
import displayWarningToast from '../../utils/displayWarningToast';
import displaySuccessToast from '../../utils/displaySuccessToast';

const MissionDetails = () => {
  const { id = '' } = useParams();
  const { refetch, data } = useGetMissionByIdQuery(id);
  const project = (data as any)?.[0];
  const [toggleFreelanceApproval] = useToggleFreelanceApprovalSprintMutation();

  const [toggle] = useToggleSprintElementDoneMutation();
  const onCheck = (e: any, sprintId: number, sprintElementId: number) => {
    toggle({
      sprintId: sprintId,
      sprintElementId: sprintElementId,
    })
      .unwrap()
      .then(() => {
        refetch();
      });
  };

  const onClick = async (sprintId: number) => {
    await toggleFreelanceApproval({ sprintId })
      .unwrap()
      .then(() => {
        displaySuccessToast('Sprint approuvé !');
      })
      .catch(() => {
        displayWarningToast("Erreur lors de l'approbation du sprint !");
      });
  };

  if (!project) return null;

  return (
    <div>
      <HeaderMission text={project?.name} />
      <Popover>
        <Popover.Button className={`absolute lg:hidden top-10 left-12`}>
          <AiOutlineMenu size={24} color="white" />
        </Popover.Button>
        <Transition.Root>
          <Transition.Child
            as={Fragment}
            enter="duration-150 ease-out"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="duration-150 ease-in"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Popover.Overlay className="fixed inset-0 bg-mainGrey/60" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="duration-150 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel>
              <SidebarMission
                closeSidebar={true}
                status={project?.status}
                payments={project?.projectPayments}
                sprints={project?.sprints}
                eventGoogleCalendar={{
                  title: project?.name,
                  location: 'Paris',
                  description: 'Description de la mission',
                  start: project?.start_date,
                  end: project?.end_date,
                }}
                id={project?.id}
              />
            </Popover.Panel>
          </Transition.Child>
        </Transition.Root>
      </Popover>

      <SidebarMission
        closeSidebar={false}
        status={project?.status}
        payments={project?.projectPayments}
        sprints={project?.sprints}
        eventGoogleCalendar={{
          title: project?.name,
          location: 'Paris',
          description: 'Description de la mission',
          start: project?.start_date,
          end: project?.end_date,
        }}
        id={project?.id}
      />

      <Flex className="flex-row lg:pl-96 pl-12 pt-5">
        <div className="font-bold text-4xl">Sprints</div>
        <div className="flex flex-wrap gap-1 md:gap-2 lg:gap-4 mx-5 lg:ml-auto text-white">
          <Link to={`/mission/${id}/dispute/new`} state={project}>
            <button className="flex w-fit p-3 bg-primary rounded-lg transition duration-150 ease-in hover:scale-105">
              <BsEmojiAngry className="mr-4 mt-1" />
              Déclarer un litige
            </button>
          </Link>
          <Link to={`/mission/${id}/disputes`} state={project}>
            <button className="flex w-fit p-3 bg-primary rounded-lg transition duration-150 ease-in hover:scale-105">
              <BsList className="mr-4 mt-1" />
              Voir les litiges
            </button>
          </Link>
          <button className="flex w-fit p-3 bg-primary rounded-lg transition duration-150 ease-in hover:scale-105">
            <AiOutlineCheck className="mr-4 mt-1" />
            Finaliser la mission
          </button>
        </div>
      </Flex>

      <Flex
        flexDirection={['column', 'column', 'row']}
        className="pr-5 pl-12 lg:pl-96 pt-8 gap-10 pb-5"
      >
        <Flex className="w-full">
          <Box
            boxShadow={'md'}
            rounded="xl"
            p={4}
            overflow="hidden"
            width={'full'}
          >
            {project?.sprints.map((sprint: any) => (
              <Box key={sprint.id} paddingBottom={4}>
                <div className="flex flex-col sm:flex-row font-bold gap-4 text-2xl bg-gray-300 -mx-4 px-4 py-4 -mt-4 justify-between">
                  <div>{sprint.name}</div>
                  <div className="flex flex-col sm:flex-row gap-3 text-lg font-normal">
                    <div className="flex flex-col gap-2">
                      {sprint.freelance_approval ? (
                        <div className="bg-green-200 p-1 rounded-lg">
                          Validé par le freelance
                        </div>
                      ) : (
                        <div className="bg-yellow-200 p-1 rounded-lg">
                          En attente de votre validation
                        </div>
                      )}
                      {sprint.freelance_approval === false &&
                        sprint.client_approval === true && (
                          <button
                            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded align-middle disabled:opacity-80"
                            onClick={() => onClick(sprint.id)}
                          >
                            Approuver le sprint
                          </button>
                        )}
                    </div>
                    <div>
                      {sprint.client_approval ? (
                        <div className="bg-green-200 p-1 rounded-lg">
                          Validé par le client
                        </div>
                      ) : (
                        <div className="bg-yellow-200 p-1 rounded-lg">
                          En attente de validation client
                        </div>
                      )}
                    </div>
                  </div>
                  <div>{sprint.price_ttc} €</div>
                </div>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Description</Th>
                      <Th isNumeric>Jours</Th>
                      <Th isNumeric>Prix TTC</Th>
                      <Th isNumeric>Terminé ?</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {sprint.sprintElements.map((element: any) => (
                      <Tr key={element.id} paddingBottom={10}>
                        <Td>{element.description}</Td>
                        <Td isNumeric>{element.quantity}</Td>
                        <Td isNumeric>{element.price_ttc}&nbsp;€</Td>
                        <Td isNumeric>
                          <input
                            className="h-5 w-5"
                            type="checkbox"
                            disabled={
                              sprint.client_approval ||
                              sprint.freelance_approval
                            }
                            onChange={(e: any) =>
                              onCheck(e, sprint.id, element.id)
                            }
                            checked={element.done}
                          />
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            ))}
          </Box>
        </Flex>
      </Flex>
    </div>
  );
};

export default MissionDetails;

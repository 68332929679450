import { api } from './api';
import { SignUpFormCompany, SignUpFormFreelancer } from '../../globals';

const endpoints = (builder: any) => ({
  signUpCompany: builder.mutation({
    query: ({ body }: { body: SignUpFormCompany }) => ({
      url: `auth/register/company`,
      method: 'POST',
      body: body,
    }),
    providesTags: ['User'],
  }),
  signUpFreelance: builder.mutation({
    query: ({ body }: { body: SignUpFormFreelancer }) => ({
      url: `auth/register/freelance`,
      method: 'POST',
      body: body,
    }),
    providesTags: ['User'],
  }),
  signIn: builder.mutation({
    query: ({ body }: { body: { email: string; password: string } }) => ({
      url: `auth/login`,
      method: 'POST',
      body: body,
    }),
  }),
  getUserAutoLogin: builder.mutation({
    query: (accessToken: string) => ({
      url: `auth/getUserAutoLogin`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }),
    providesTags: ['User'],
  }),
  verifyClientPublicToken: builder.mutation({
    query: (publicToken: string) => ({
      url: `auth/login-client/${publicToken}`,
      method: 'GET',
    }),
  }),
  sendEmail: builder.mutation({
    query: ({ body }: { body: { email: string } }) => ({
      url: `/auth/reset-password`,
      method: 'POST',
      body: body,
    }),
  }),
  changePassword: builder.mutation({
    query: ({ body }: { body: { token: string; password: string } }) => ({
      url: `/auth/reset-password`,
      method: 'POST',
      body: body,
    }),
  }),
  deleteUser: builder.mutation({
    query: () => ({
      url: `/user`,
      method: 'DELETE',
    }),
  }),
});

export const userApi = api.injectEndpoints({ endpoints });
export const {
  useSignUpCompanyMutation,
  useSignUpFreelanceMutation,
  useSignInMutation,
  useGetUserAutoLoginMutation,
  useVerifyClientPublicTokenMutation,
  useSendEmailMutation,
  useChangePasswordMutation,
  useDeleteUserMutation,
} = userApi;

export const selectCurrentUser = (state: any) => state.api.data;

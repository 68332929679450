import { Link } from 'react-router-dom';
import React from 'react';

const services = [
  {
    title: 'Web Developer',
  },
  {
    title: 'React Native Developer',
  },
  {
    title: 'Backend Developer',
  },
  {
    title: 'Content Creator',
  },
];

const notificationTypes: {
  [key: string]: {
    title: string;
    description: string;
    compFactory: (data: any) => JSX.Element;
  };
} = {
  mission_created: {
    title: 'Nouvelle mission',
    description: 'Une nouvelle mission a été créée.',
    compFactory: (data: any) => {
      return (
        <div>
          <Link to={`/mission/${data.id}`}>
            <h1 className="font-bold">Accéder à la mission</h1>
          </Link>
        </div>
      );
    },
  },
  mission_finished: {
    title: 'Mission terminée',
    description: 'Une mission a été terminée.',
    compFactory: (data: any) => {
      return (
        <div>
          <Link to={`/mission/${data.id}`}>
            <h1 className="font-bold">Accéder à la mission</h1>
          </Link>
        </div>
      );
    },
  },
  pending_signature: {
    title: 'Signature en attente',
    description: 'Un document est en attente de signature.',
    compFactory: (data: any) => {
      return (
        <div>
          <Link to={`/mission/${data.projectId}/document/${data.documentId}`}>
            <h1 className="font-bold">Accéder au document</h1>
          </Link>
        </div>
      );
    },
  },
  succeeded_signature: {
    title: 'Signature réussie',
    description: 'Un document a été signé.',
    compFactory: (data: any) => {
      return (
        <div>
          <Link to={`/mission/${data.project.id}`}>
            <h1 className="font-bold">Accéder au document</h1>
          </Link>
        </div>
      );
    },
  },
  update_proposal_created: {
    title: 'Proposition de modification',
    description: 'Une proposition de modification a été créée.',
    compFactory: (data: any) => {
      return (
        console.log(data),
        (
          <div>
            <Link
              to={`/mission/${data.project.id}/modification-proposal/${data.additionalInformation?.modificationProposalId}`}
            >
              <h1 className="font-bold">
                Accéder à la proposition de modification
              </h1>
            </Link>
          </div>
        )
      );
    },
  },
  dispute_created: {
    title: 'Litige créé',
    description: 'Un litige a été créé.',
    compFactory: (data: any) => {
      return (
        <div>
          <Link
            to={`/mission/${data.project.id}/dispute/${data.additionalInformation?.id}`}
          >
            <h1 className="font-bold">Accéder au litige</h1>
          </Link>
        </div>
      );
    },
  },
};

export { services, notificationTypes };

import React, { useState } from 'react';
import DashboardLayout from '../../Layout/DashboardLayout';
import { BsBriefcaseFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Table from '../../Components/molecules/Table';
import { AiFillEdit, AiFillEye } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { useGetAllClientsQuery } from '../../redux/services/clients';

const ClientList = () => {
  const user = useSelector((state: any) => state.user.user);
  const { data: clients, isLoading, isSuccess } = useGetAllClientsQuery({});
  const navigate = useNavigate();

  const [clientNameFilter, setClientNameFilter] = useState('');
  const [entrepriseFilter, setEntrepriseFilter] = useState('');
  const [showFilters, setShowFilters] = useState(false);

  const handleClientNameFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setClientNameFilter(e.target.value);
  };

  const handleEntrepriseFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEntrepriseFilter(e.target.value);
  };

  const handleFilterButtonClick = () => {
    setShowFilters(!showFilters);
  };

  const filteredClients = (clients as any[])?.filter((client: any) => {
    console.log(client);

    return (
      client.first_name
        .toLowerCase()
        .includes(clientNameFilter.toLowerCase()) ||
      (client.last_name
        .toLowerCase()
        .includes(clientNameFilter.toLowerCase()) &&
        client.company_name
          .toLowerCase()
          .includes(entrepriseFilter.toLowerCase()))
    );
  });

  const tableData = filteredClients?.map((freelance: any) => ({
    row: [
      `${freelance?.first_name} ${freelance?.last_name}`,
      freelance?.phone,
      freelance?.email,
      freelance?.company_name,
    ],
    actions: [
      {
        label: <AiFillEye size={24} />,
        onClick: () => navigate(`/client/${freelance.id}`),
      },
    ],
  }));

  if (isLoading) {
    return (
      <DashboardLayout>
        <div className="flex justify-center items-center h-screen flex-col">
          <h1 className="text-xl font-bold" style={{ color: '#2563EB' }}>
            Chargement de vos clients en cours...
          </h1>
        </div>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <div className="flex flex-col sm:flex-row justify-between items-center mb-8">
        <div className="text-4xl md:ml-8 text-mainBlack sm:ml-8 max-[640px]:text-center max-[640px]:my-4 max-[640px]:ml-8">
          Mes clients
        </div>
        {showFilters && (
          <div>
            <input
              className="border-primary border-2 rounded-md p-2"
              type="text"
              value={clientNameFilter}
              onChange={handleClientNameFilterChange}
              placeholder="Client"
            />
            <input
              type="text"
              className="ml-2 border-primary border-2 rounded-md p-2"
              value={entrepriseFilter}
              onChange={handleEntrepriseFilterChange}
              placeholder="Entreprise"
            />
          </div>
        )}
        <button
          onClick={handleFilterButtonClick}
          className="flex bg-primary hover:bg-blue-600 text-white py-2 px-4 mb-4 mr-4 rounded "
        >
          {showFilters ? 'Hide Filters' : 'Show Filters'}
        </button>
        <Link className="max-[640px]:ml-8" to="/client/new">
          <button className="flex bg-primary hover:bg-blue-600 text-white py-2 px-4 mb-4 mr-4 rounded ">
            <BsBriefcaseFill size={20} className="mr-2 text-white" />
            Créer un client
          </button>
        </Link>
      </div>
      <Table
        columns={['CLIENT', 'TELEPHONE', 'EMAIL', 'ENTREPRISE', 'ACTIONS']}
        data={tableData}
        checkbox={false}
      />
    </DashboardLayout>
  );
};

export default ClientList;

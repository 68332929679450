import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';
import { userApi } from '../services/auth';
import { notificationApi } from '../services/notification';
import { socket } from '../../socket/socket';

const initialState = {
  user: null,
  accessToken: null,
  refreshToken: null,
  isLogged: false,
  isGoogleLogged: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: () => {
      socket.disconnect();
      return initialState;
    },
    autoLogin: (state, action) => {
      const { user, accessToken, refreshToken } = action.payload;
      state.accessToken = accessToken;
      state.user = user;
      state.isLogged = true;
    },
    connectGoogle: (state, action) => {
      state.isGoogleLogged = action.payload;
    },
    onNewNotification: (state, action) => {
      state.user = {
        ...(state.user as any),
        notifications: [
          ...((state.user as any)?.notifications || []),
          action.payload.notification,
        ],
      };
    },
    updateUser: (state, action) => {
      state.user = action.payload;
      console.log(state.user);
    },
    updateAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(PURGE, () => {
        return initialState;
      })
      .addMatcher(userApi.endpoints.signIn.matchPending, () => {})
      .addMatcher(
        userApi.endpoints.signIn.matchFulfilled,
        (state: any, action: { payload: any }) => {
          const { user, RefreshToken, accessToken } = action?.payload;
          state.user = user;
          state.accessToken = accessToken;
          state.refreshToken = RefreshToken;
          state.isLogged = true;
        }
      )
      .addMatcher(userApi.endpoints.signIn.matchRejected, () => {})
      .addMatcher(userApi.endpoints.signUpFreelance.matchPending, () => {})
      .addMatcher(
        userApi.endpoints.signUpFreelance.matchFulfilled,
        (state: any, action: { payload: any }) => {
          const { user, refreshToken, accessToken } = action?.payload;
          state.user = user;
          state.accessToken = accessToken;
          state.refreshToken = refreshToken;
          state.isLogged = true;
        }
      )
      .addMatcher(userApi.endpoints.signUpFreelance.matchRejected, () => {})
      .addMatcher(userApi.endpoints.signUpCompany.matchPending, () => {})
      .addMatcher(
        userApi.endpoints.signUpCompany.matchFulfilled,
        (state: any, action: { payload: any }) => {
          const { user, RefreshToken, accessToken } = action?.payload;
          state.user = user;
          state.accessToken = accessToken;
          state.refreshToken = RefreshToken;
          state.isLogged = true;
        }
      )
      .addMatcher(userApi.endpoints.signUpCompany.matchRejected, () => {})
      .addMatcher(
        (action) => action.type === 'user/autoLogin',
        (state, action) => {
          state.accessToken = action.payload;
          state.isLogged = true;
        }
      )
      .addMatcher(
        notificationApi.endpoints.markAsRead.matchFulfilled,
        (state: any, action: { payload: any }) => {
          state.user.notifications = action.payload.notifications;
        }
      )
      .addMatcher(
        notificationApi.endpoints.delete.matchFulfilled,
        (state: any, action: { payload: any }) => {
          state.user.notifications = action.payload.notifications;
        }
      )
      .addMatcher(
        userApi.endpoints.verifyClientPublicToken.matchFulfilled,
        (state: any, action: { payload: any }) => {
          const { user } = action?.payload;
          state.user = user;
          state.isLogged = true;
        }
      );
  },
});

export const {
  logout,
  autoLogin,
  connectGoogle,
  onNewNotification,
  updateUser,
  updateAccessToken,
} = userSlice.actions;
export const selectUser = (state: any) => state?.user?.user;

export default userSlice.reducer;

import React from 'react';
import { FaHandsHelping } from 'react-icons/fa';
import { AiFillThunderbolt } from 'react-icons/ai';
import {
  BsShieldLock,
  BsFillGearFill,
} from 'react-icons/bs';
import { motion } from 'framer-motion';
import { InView } from 'react-intersection-observer';

import tempWindow from '../../assets/temp_window.png';
import relaxLanding from '../../assets/relax_landing.png';
import dashboard from '../../assets/dashboard.png';

const BenefitSection = () => {
  return (
    <div>
      {/* Sécurité */}
      <section className="relative mx-6 z-10 mb-10" id="about">
        <div className="flex justify-start gap-4 items-stretch">
          <InView trackVisibility delay={1500}>
            {({ inView, ref, entry }) => (
              <div className="flex flex-col items-center" ref={ref}>
                <div
                  className={`flex transition-all duration-300 justify-center items-center bg-primary rounded-full min-w-[75px] min-h-[75px] ${inView ? 'opacity-100' : 'opacity-0'
                    }`}
                >
                  <BsShieldLock size={40} color="#fff" />
                </div>
                <div
                  className={`bg-gradient-to-b from-[#1552F0] to-[#ffffff] w-[3px] transition-all duration-[1500ms] lg:w-[4px] ${inView ? 'h-full' : 'h-0'
                    }`}
                ></div>
              </div>
            )}
          </InView>
          <div className="flex flex-col gap-4">
            <div className="text-4xl flex flex-col gap-2">
              <motion.p
                className="text-primary font-light"
                initial={{ opacity: 0, y: 20 }}
                animate={InView ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 0.3, delay: 0.2 }}
              >
                Sécurité
              </motion.p>
              <motion.h1
                className="text-primary font-bold"
                initial={{ opacity: 0, y: 20 }}
                animate={InView ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 0.3, delay: 0.3 }}
              >
                La tranquillité d&apos;esprit assurée avec Luter
              </motion.h1>
              <motion.h1
                className=""
                initial={{ opacity: 0, y: 20 }}
                animate={InView ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 0.3, delay: 0.4 }}
              >
                Aucune mission impayée.
              </motion.h1>
              <motion.h1
                className=""
                initial={{ opacity: 0, y: 20 }}
                animate={InView ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 0.3, delay: 0.5 }}
              >
                Aucun retard de paiement.
              </motion.h1>
            </div>
            <div className="xs:hidden sm:block md:block ml-auto">
              <img src={dashboard} alt="demo" />
            </div>
          </div>
        </div>
      </section>
      {/* Simplicité */}
      <section className="relative mx-6 z-10 mb-10">
        <div className="flex justify-start gap-4 items-stretch">
          <InView trackVisibility delay={1500}>
            {({ inView, ref, entry }) => (
              <div className="flex flex-col items-center" ref={ref}>
                <div
                  className={`flex transition-all duration-300 justify-center items-center bg-primary rounded-full min-w-[75px] min-h-[75px] ${inView ? 'opacity-100' : 'opacity-0'
                    }`}
                >
                  <AiFillThunderbolt size={40} color="#fff" />
                </div>
                <div
                  className={`bg-gradient-to-b from-[#1552F0] to-[#ffffff] w-[3px] transition-all duration-[1500ms] lg:w-[4px] ${inView ? 'h-full' : 'h-0'
                    }`}
                ></div>
              </div>
            )}
          </InView>
          <div className="flex flex-col gap-4 flex-wrap">
            <div className="text-4xl flex flex-col gap-2">
              <motion.p
                className="text-primary font-light"
                initial={{ opacity: 0, y: 20 }}
                animate={InView ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 0.3, delay: 0.2 }}
              >
                Simplicité
              </motion.p>
              <motion.h1
                className="text-primary font-bold"
                initial={{ opacity: 0, y: 20 }}
                animate={InView ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 0.3, delay: 0.3 }}
              >
                Focalisez-vous sur l&apos;essentiel avec Luter
              </motion.h1>
              <motion.h1
                className=""
                initial={{ opacity: 0, y: 20 }}
                animate={InView ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 0.3, delay: 0.4 }}
              >
                Invitez vos clients, signez le cahier des charges et
                confiez-nous le reste.
              </motion.h1>
            </div>
          </div>
          <div className="flex flex-wrap xs:hidden sm:hidden md:block">
            {/* <Spline
      scene="https://prod.spline.design/3OKn11ZHzmJTTes3/scene.splinecode"
      className="lg:scale-100 md:m-0 md:scale-50 sm:-mx-10 sm:scale-25"
      id="spline-scene"
    /> */}
            <video loop autoPlay muted className="lg:scale-100 md:m-0 md:scale-50 sm:-mx-10 sm:scale-25">
              <source src="src\assets\dashboard.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </section>
      {/* Accompagnement */}
      <section className="relative mx-6 z-10 mb-10">
        <div className="flex justify-start gap-4 items-stretch">
          <InView trackVisibility delay={1500}>
            {({ inView, ref, entry }) => (
              <div className="flex flex-col items-center" ref={ref}>
                <div
                  className={`flex transition-all duration-300 justify-center items-center bg-primary rounded-full min-w-[75px] min-h-[75px] ${inView ? 'opacity-100' : 'opacity-0'
                    }`}
                >
                  <FaHandsHelping size={40} color="#fff" />
                </div>
                <div
                  className={`bg-gradient-to-b from-[#1552F0] to-[#ffffff] w-[3px] transition-all duration-[1500ms] lg:w-[4px] ${inView ? 'h-full' : 'h-0'
                    }`}
                ></div>
              </div>
            )}
          </InView>
          <div className="flex flex-col gap-4 flex-wrap">
            <div className="text-4xl flex flex-col gap-2">
              <motion.p
                className="text-primary font-light"
                initial={{ opacity: 0, y: 20 }}
                animate={InView ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 0.3, delay: 0.2 }}
              >
                Accompagnement
              </motion.p>
              <motion.h1
                className="text-primary font-bold"
                initial={{ opacity: 0, y: 20 }}
                animate={InView ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 0.3, delay: 0.3 }}
              >
                Soyez maître de votre travail avec Luter
              </motion.h1>
              <motion.h1
                className=""
                initial={{ opacity: 0, y: 20 }}
                animate={InView ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 0.3, delay: 0.4 }}
              >
                Profitez d&apos;un espace partagé avec votre client et
                d&apos;un accompagnement adapté pour chaque échange.
              </motion.h1>
            </div>
          </div>
          <div className="xs:hidden sm:hidden md:block ml-auto">
            <img src={tempWindow} alt="demo" />
          </div>
        </div>
      </section>

      {/* Modularité */}
      <section className="relative mx-6 z-10 mb-10">
        <div className="flex justify-start gap-4 items-stretch">
          <InView trackVisibility delay={1500}>
            {({ inView, ref, entry }) => (
              <div className="flex flex-col items-center" ref={ref}>
                <div
                  className={`flex transition-all duration-300 justify-center items-center bg-primary rounded-full min-w-[75px] min-h-[75px] ${inView ? 'opacity-100' : 'opacity-0'
                    }`}
                >
                  <BsFillGearFill size={40} color="#fff" />
                </div>
                <div
                  className={`bg-gradient-to-b from-[#1552F0] to-[#ffffff] w-[3px] transition-all duration-[1500ms] lg:w-[4px] ${inView ? 'h-full' : 'h-0'
                    }`}
                ></div>
              </div>
            )}
          </InView>
          <div className="flex flex-col gap-4">
            <div className="text-4xl flex flex-col gap-2">
              <p className="text-primary font-light">Modularité</p>
              <h1 className="text-primary font-bold">
                Personnalisez votre utilisation de Luter
              </h1>
              <h1 className="">
                Sélectionnez les fonctionnalités qui répondent à vos besoins.
              </h1>
            </div>
          </div>
          <div className="xs:hidden sm:hidden md:block ml-auto">
            <img src={relaxLanding} alt="demo" className="ml-auto" />
          </div>
        </div>
      </section>
    </div>
  );
};

export default BenefitSection;
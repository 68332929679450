import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ThreeDots } from 'react-loader-spinner';
import ProgressBar from '../ProgressBar';
import BulleForm from '../BulleForm';

import { SignUpFormFreelancer } from '../../globals';
import { useSignUpFreelanceMutation } from '../../redux/services/auth';

const RegisterFormInfo2 = ({ onPrev, data, setData }: any) => {
  const [isFirstNameValid, setIsConfirmFirstNameValid] =
    useState<boolean>(true);
  const [isLastNameValid, setIsConfirmLastNameValid] = useState<boolean>(true);
  const [isAddressValid, setIsConfirmAddressValid] = useState<boolean>(true);
  const [isZipValid, setIsConfirmZipValid] = useState<boolean>(true);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const navBarHeight = 82.16;
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);

  const [userSignUp, { isLoading, isSuccess, isError }] =
    useSignUpFreelanceMutation();
  const navigate = useNavigate();

  useEffect(() => {
    // Set default values for SIRET and TVA
    setData({
      ...data,
      siret: '12345678901234', // Default SIRET value
      tva: 'FRXX123456789', // Default TVA value
    });
  }, []);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormSubmitted(true);

    const firstNameValid = data.firstName !== '';
    const lastNameValid = data.lastName !== '';
    const addressValid = data.address !== '';
    const zipValid = data.zip !== '';

    setIsConfirmFirstNameValid(firstNameValid);
    setIsConfirmLastNameValid(lastNameValid);
    setIsConfirmAddressValid(addressValid);
    setIsConfirmZipValid(zipValid);

    if (!firstNameValid || !lastNameValid || !addressValid || !zipValid) {
      toast.warn('Missing Field', {
        toastId: 'MissingField',
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      return;
    }

    if (data.password !== data.confirmPassword) {
      toast.warn('Les mots de passe ne correspondent pas', {
        toastId: 'passwordsDontMatch',
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      return;
    }

    setData({ ...data, loading: true });
    setIsLoad(true);

    const formData: SignUpFormFreelancer = {
      address: data.address,
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      password: data.password,
      phone: data.phone,
      siret: data.siret,
      tva_number: data.tva,
      company_name: data.society,
      white_label: data.whiteLabel,
    };

    userSignUp({ body: formData })
      .unwrap()
      .then((res: any) => {
        setData({ ...data, loading: false });
        toast.success(
          'Vous allez être redirigé vers notre partenaire Stripe pour finaliser votre inscription',
          {
            toastId: 'successSignUp',
            position: 'top-left',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
            closeButton: false,
            progress: undefined,
            theme: 'light',
          }
        );
        setTimeout(() => {
          window.open(res.stripe_setup_url, '_blank', 'noreferrer');
        }, 5000);
      })
      .catch((err: any) => {
        if (err.status === 400) {
          toast.warn(err.data.error, {
            toastId: 'badEmailOrUserAlreadyExists',
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
        }
        setData({ ...data, loading: false });
      });
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      className="flex h-screen overflow-hidden p-20 mt-20"
      style={{ height: `calc(100vh - ${navBarHeight}px)` }}
    >
      {windowWidth > 768 && (
        <div className="w-full md:w-2/5 pl-4 pr-10" style={{ height: '100%' }}>
          <BulleForm
            title="Dernière ligne droite !"
            subtitle="Aucun engagement"
            text="Vous êtes libre de modifier à n'importe quel moment vos informations. Si vous souhaitez d'ores et déjà vous renseigner sur les différentes offres de Luter, vous pouvez consulter notre page tarifs."
            buttonText="Consulter nos tarifs"
            buttonLink="/guidance"
          />
        </div>
      )}
      <form
        onSubmit={handleSubmit}
        className="flex flex-col md:w-2/3 rounded-md pr-10 justify-between"
      >
        <ProgressBar percent={99} className="absolute top-0 left-0" />
        <div className="grid gap-6 mb-6 md:grid-cols-2 py-10 mt-20">
          <div className="mb-4">
            <label htmlFor="firstName" className="block font-bold mb-2">
              Prénom
            </label>
            <input
              type="text"
              name="firstName"
              value={data.firstName}
              placeholder="John"
              onChange={(e) => {
                setData({ ...data, firstName: e.target.value });
              }}
              className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                formSubmitted && !isFirstNameValid ? 'border-red-500' : ''
              }`}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="lastName" className="block font-bold mb-2">
              Nom
            </label>
            <input
              type="text"
              name="lastName"
              value={data.lastName}
              placeholder="Doe"
              onChange={(e) => {
                setData({ ...data, lastName: e.target.value });
              }}
              className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                formSubmitted && !isLastNameValid ? 'border-red-500' : ''
              }`}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="address" className="block font-bold mb-2">
              Adresse postale
            </label>
            <input
              type="text"
              name="address"
              value={data.address}
              placeholder="1 rue de la Paix"
              onChange={(e) => {
                setData({ ...data, address: e.target.value });
              }}
              className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                formSubmitted && !isAddressValid ? 'border-red-500' : ''
              }`}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="zip" className="block font-bold mb-2">
              Ville
            </label>
            <input
              type="text"
              name="zip"
              value={data.zip}
              placeholder="Paris"
              onChange={(e) => {
                setData({ ...data, zip: e.target.value });
              }}
              className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                formSubmitted && !isZipValid ? 'border-red-500' : ''
              }`}
            />
          </div>
        </div>
        <div className="flex items-center justify-between">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            onClick={onPrev}
          >
            Précédent
          </button>
          {isLoad ? (
            <ThreeDots color="#1552F0" height={30} width={30} />
          ) : (
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Suivant
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default RegisterFormInfo2;

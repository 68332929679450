import React from 'react';
import {
  RiBillFill,
  RiContactsBookFill,
  RiMoneyCnyBoxFill,
} from 'react-icons/ri';
import { BsFillGrid1X2Fill, BsBriefcaseFill } from 'react-icons/bs';
import Logo from '../assets/logo_luter_final.svg';
import { Link } from 'react-router-dom';

type Props = {
  username: string;
  missionId: number;
};

const SidebarClient: React.FC<Props> = ({ username }) => {
  const token = new URLSearchParams(location.search).get('token');

  return (
    <div className="inset-0 mx-8 mt-8 h-[90vh] z-50 w-fit hidden lg:flex">
      {/* Sidebar */}
      <div className="flex flex-col justify-between items-center w-64 h-full overflow-y-auto shadow-2xl bg-creamWhite rounded-[30px] p-6 border border-whiteGrey">
        <div className="flex flex-col justify-center items-center gap-8">
          <div className="flex items-center justify-center gap-2">
            <img src={Logo} alt="Logo" className="w-10 h-auto" />
            <p className="text-primary text-4xl">luter</p>
          </div>
          <div className="text-center">
            <p className="text-xl mt-8 mb-2 text-mainBlack">Bonjour,</p>
            <p className="text-primary text-xl mb-4">{username}</p>
          </div>
          <div className="px-4 flex flex-col">
            <ul className="flex flex-col justify-center">
              <li className="mb-8">
                <Link to={`/client/dashboard?token=${token}`}>
                  <button className="flex items-center justify-center">
                    <BsFillGrid1X2Fill
                      size={20}
                      className="mr-2 text-primary"
                    />
                    <span className="text-xl text-mainBlack hover:text-primary">
                      Dashboard
                    </span>
                  </button>
                </Link>
              </li>
              <li className="mb-8">
                <Link to={`/client/mission/details?token=${token}`}>
                  <button className="flex items-center justify-center">
                    <BsBriefcaseFill size={20} className="mr-2 text-primary" />
                    <span className="text-xl text-mainBlack hover:text-primary">
                      Mission
                    </span>
                  </button>
                </Link>
              </li>
              <li className="mb-8">
                <Link to={`/client/freelance?token=${token}`}>
                  <button className="flex items-center justify-center">
                    <RiContactsBookFill
                      size={24}
                      className="mr-2 text-primary"
                    />
                    <span className="text-xl text-mainBlack hover:text-primary">
                      Freelance
                    </span>
                  </button>
                </Link>
              </li>
              <li className="mb-8">
                <Link to={`/client/transactions?token=${token}`}>
                  <button className="flex items-center justify-center">
                    <RiBillFill size={24} className="mr-2 text-primary" />
                    <span className="text-xl text-mainBlack hover:text-primary">
                      Transactions
                    </span>
                  </button>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarClient;

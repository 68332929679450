import React from 'react';
import FormulaCalculator from './FormulaCalculator';
import { FormStateType } from '../../redux/feature/formSlice';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from '../Button';


function FormResults() {
  const {
    price,
    duration,
    tarificationHonnete,
    delaisCohérents,
    confiance,
    missions,
    techno,
    clientTravail,
    clientParticulier,
    clientLuter
  } = useSelector((state: any) => state.form) as FormStateType;

  const navigate = useNavigate();
  return (
    <div className="bg-gradient-to-b from-blue-500 to-blue-900 min-h-screen p-8">
      <div className="container mx-auto py-16 mt-10">
        <h1 className="text-4xl font-bold text-white mb-8">
          Résultats de l'analyse
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          <div className="bg-white rounded-lg p-6 shadow-md">
            <p className="text-xl text-center font-semibold mb-4">Voici les informations que vous avez renseignées :</p>
            <ul className="list-disc list-inside ">
              <li>Le prix total est de <b>{price}</b> €.</li>
              <li>La durée estimée est de <b>{duration}</b> J/H.</li>
              <li>La tarification vous semble-t-elle honnête ? <b>{tarificationHonnete}</b></li>
              <li>Les délais sont-ils cohérents ? <b>{delaisCohérents}</b> </li>
              <li>Votre niveau de confiance : <b>{confiance}</b></li>
              <li>Nombre de missions réalisées : <b>{missions}</b></li>
              <li>Technologie utilisée : <b>{techno}</b></li>
              <li>Vous avez déjà travaillé avec ce client : <b>{clientTravail}</b></li>
              <li>Travail pour des clients particuliers : <b>{clientParticulier}</b></li>
              <li>Travail pour Luter : <b>{clientLuter}</b></li>
            </ul>
          </div>
          <div className="bg-white rounded-lg p-6 shadow-md">
          <p className="text-xl text-center font-semibold mb-4">Nous vous recommandons la formule...</p>
          <div className="grid place-content-center">
            <FormulaCalculator
              price={price}
              duration={duration}
              tarificationHonnete={tarificationHonnete}
              delaisCohérents={delaisCohérents}
              confiance={confiance}
              missions={missions}
              techno={techno}
              clientTravail={clientTravail}
              clientParticulier={clientParticulier}
              clientLuter={clientLuter}
            />
            </div>
          </div>
        </div>
      </div>
      <div className="-mt-5 grid place-content-center">
      <Button
        label="Retour à l'accueil"
        primary
        onClick={() => navigate('/')}
      />
      </div>
    </div>
  );
};

export default FormResults;

import React from 'react';
import GoogleCalendarConnectButton from '../Components/molecules/GoogleCalendarConnectButton';
import { useNavigate, useParams } from 'react-router-dom';
import { Props } from './interface/mission';

function renderStatus(status: string) {
  switch (status) {
    case 'completed':
      return (
        <div className="bg-green-300 bg-opacity-40 text-green-900 rounded-md w-44 h-14 font-bold text-xl text-center pt-4">
          Terminé
        </div>
      );
    case 'cancelled':
      return (
        <div className="bg-red-300 bg-opacity-40 text-red-900 rounded-md w-44 h-14 text-xl font-bold text-center pt-4">
          Annulé
        </div>
      );
    default:
      return (
        <div className="bg-orange-300 bg-opacity-40 text-orange-900 rounded-md w-44 h-14 text-xl font-bold text-center pt-4">
          En Cours
        </div>
      );
  }
}

function renderPaymentNames(payments: any) {
  return (
    payments?.length &&
    payments.map(
      (payment: { name: string }, index: React.Key | null | undefined) => (
        <span key={index} className="capitalize">
          {payment.name.replace(/_/g, ' ')}
          {index !== payments.length - 1 ? ' / ' : null}
        </span>
      )
    )
  );
}

function priceCalculator(sprints: any) {
  let total = 0;
  sprints?.length &&
    sprints.forEach((sprint: { price_ttc: number }) => {
      total += sprint.price_ttc;
    });
  return (
    <div className="flex flex-col justify-center items-center">
      <p className="text-primary mt-16 text-2xl"> Total </p>
      <p> {total} € </p>
    </div>
  );
}

const SidebarMission = (props: any) => {
  const navigate = useNavigate();

  return (
    <div
      className={`inset-0 mx-8 mt-8 pb-16 h-screen z-50 w-fit lg:flex absolute ${
        !props.closeSidebar ? 'hidden' : 'absolute -mt-16'
      } `}
    >
      <div className="flex flex-col justify-between items-center w-64 h-full overflow-y-auto shadow-2xl bg-creamWhite rounded-[30px] p-6">
        <div className="flex flex-col justify-center items-center gap-8">
          <div className="px-4 flex flex-col bg-opacity-40">
            {renderStatus(props.status)}
          </div>
          <GoogleCalendarConnectButton
            purpose="synchronize"
            event={props.eventGoogleCalendar}
          />
          <div className="text-center text-xl">
            <p className="text-primary mt-16 text-2xl"> Type de Paiement </p>
            {renderPaymentNames(props.payments)}
            {priceCalculator(props.sprints)}
          </div>
          <div className="absolute bottom-20">
            <button
              onClick={() => navigate('/mission/' + props.id + '/documents')}
              className="rounded-md p-2 bg-primary text-white font-bold"
            >
              Accéder aux documents
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarMission;

import React, { useState, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
// Icon
import { BiTrash } from 'react-icons/bi';
// Components
import SettingsMenu from '../../Components/Dashboard/SettingsMenu';
import Button from '../../Components/Button';
import DashboardLayout from '../../Layout/DashboardLayout';
import SettingsMenuSmall from '../../Components/Dashboard/SettingsMenuSmall';

const SettingsKeyAPI: React.FC = () => {
  const dummyProfiles = [
    {
      id: 1,
      tokenName: 'Test1',
      keys: [
        ['Test1', 'X0...tFa8', '18/06/23'],
        ['Test1', 'X0...tFa8', '18/06/23'],
      ],
    },
  ];

  // useState
  const navigate = useNavigate();
  const [tokenName, setTokenName] = useState(dummyProfiles[0].tokenName);
  const nbOfKeysActives = dummyProfiles[0].keys.length;

  const handleTokenChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setTokenName(e.target.value);
  };

  return (
    <DashboardLayout>
      <div className="flex">
        <div className="w-full p-4">
          <div className="flex flex-row mb-10">
            <h1 className="text-left text-4xl font-bold bold-4 w-1/2 text-left">
              Paramètres du profil
            </h1>
            <div className="w-1/2 text-right">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                onClick={() => navigate('/profile')}
              >
                Voir votre profil publique
              </button>
            </div>
          </div>
          <div className="md:hidden xs:block w-1/4 pr-4">
            <SettingsMenuSmall />
          </div>
          <div className="flex h-full overflow-y-auto shadow-2xl bg-creamWhite rounded-[30px] p-6 xs:-mr-8 md:mr-0">
            <div className="md:block xs:hidden w-1/4 pr-4">
              <SettingsMenu />
            </div>
            <div className="xs:hidden md:block w-px bg-gray-300 mx-4" />

            <div className="w-full">
              {/* Section 1 */}
              <div className="w-full overflow-y-auto border-2 bg-creamWhite rounded-[5px] p-4 mb-5">
                <div className="flex items-center justify-between">
                  <h1 className="font-bold text-3xl mt-4">Clés API</h1>
                </div>
                <h3 className="text-cyan-900 ml-5">
                  Générer un token API pour permettre aux applications
                  d&apos;accéder à certaines de vos données Luter
                </h3>
                <hr className="my-2" />
                <div className="flex flex-row mt-10 mx-20">
                  <div className="flex">
                    <input
                      type="text"
                      placeholder={dummyProfiles[0].tokenName}
                      value={tokenName}
                      onChange={handleTokenChange}
                      className="w-full md:w-60 border border-black border-2 rounded-md mx-20 text-left"
                    />
                    <Button
                      label="Générer le token"
                      primary
                      onClick={() => navigate('/')}
                    />
                  </div>
                </div>
              </div>

              {/* Section 2 */}
              <div className="w-full overflow-y-auto border-2 bg-creamWhite rounded-[5px] p-4 mb-5">
                <div className="flex items-center justify-between">
                  <h1 className="font-bold text-3xl mt-4">Clés existentes</h1>
                </div>
                <h3 className="text-cyan-900 ml-5">
                  Liste de vos clés déjà créées
                </h3>
                <hr className="my-2" />
                <div className="flex flex-col mt-10 mx-20 items-center">
                  <div className="flex flex-row items-center">
                    <h2 className="text-cyan-800 ml-10">NOM</h2>
                    <h2 className="text-cyan-800 ml-24">CLÉ</h2>
                    <h2 className="text-cyan-800 ml-24">CRÉÉ LE</h2>
                    <h2 className="text-cyan-800 ml-10">SUPPRIMER</h2>
                  </div>
                </div>
                <hr className="my-2" />
                <div className="flex flex-col mt-10 mx-20 items-center">
                  {dummyProfiles[0].keys.map((session, index) => (
                    <div className="flex flex-row items-center" key={index}>
                      <h2 className="text-black font-bold bold-2">
                        {session[0]}
                      </h2>
                      <p className="text-black font-bold bold-2 ml-20 text-sm">
                        {session[1]}
                      </p>
                      <p className="text-black font-bold bold-2 ml-20 text-sm">
                        {session[2]}
                      </p>
                      <div className="flex ml-20 items-center">
                        <BiTrash className="cursor-pointer text-red-500 hover:text-red-700" />
                      </div>
                      {index !== nbOfKeysActives - 1 && <hr className="my-2" />}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default SettingsKeyAPI;

import React from 'react';
import ChatbotHeader from './ChatbotHeader';
import ChatbotChat from './ChatbotChat';

export const ChatbotChatBox = ({ onClickClose }: ChatbotChatBoxProps) => {
  return (
    <div>
      <div className="flex flex-col justify-between items-center rounded-t-lg z-[49] w-96 h-96 bg-creamWhite rounded-br-md rounded-bl-md shadow-md">
        <ChatbotHeader onClickClose={onClickClose} />
        <ChatbotChat />
      </div>
    </div>
  );
};

export interface ChatbotChatBoxProps {
  onClickClose: any;
}

export default ChatbotChatBox;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Navbar from '../../../Components/Navbar';
import Footer from '../../../Components/Footer';
import { TbMailbox } from 'react-icons/tb';

const EmailSent: React.FC = () => {
  return (
    <div className="flex flex-col justify-between">
      <Navbar isLogged={false} />
      <div className="flex flex-col justify-center items-center gap-10 h-screen">
        <h1 className="text-4xl font-semibold mb-4">
          Encore un voyage dans la boite mail
        </h1>
        <TbMailbox size={100} className="text-blue-500" />
        <p className="block text-gray-700 text-md">
          Si votre email est enregistré, vous recevrez un lien de
          réinitialisation.
        </p>
        <Link to="/faq" className="text-blue-500 underline hover:text-blue-400">
          Besoin d&apos;aide ?
        </Link>
      </div>
      <Footer />
    </div>
  );
};

export default EmailSent;

import React, { useCallback, useState } from 'react';
import { AiOutlineWechat } from 'react-icons/ai';
import ChatbotChatBox from './ChatbotChatBox';

export const Chatbot = (props: ChatbotProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClickOpen = useCallback((e: any) => {
    e.preventDefault();
    setIsOpen(true);
  }, []);

  const onClickClose = useCallback((e: any) => {
    e.preventDefault();
    setIsOpen(false);
  }, []);

  return (
    <div className="fixed bottom-7 right-7">
      <button
        onClick={onClickOpen}
        className="bg-gradient rounded-full w-16 h-16 hover:scale-110 transition-all duration-200 z-50"
      >
        <div className="flex justify-center items-center w-full h-full relative">
          <AiOutlineWechat size={32} className="text-white" />
        </div>
      </button>
      {isOpen && (
        <div className="absolute bottom-0 right-0">
          <ChatbotChatBox onClickClose={onClickClose} />
        </div>
      )}
    </div>
  );
};

export interface ChatbotProps {}

export default Chatbot;

import React, { useEffect, useRef } from 'react';
import ChatbotFeedMessage from './ChatbotFeedMessage';
import { IoIosHelpBuoy } from 'react-icons/io';
import { ThreeDots } from 'react-loader-spinner';
import { type chatHistory } from '../../globals';

export const ChatbotFeed = ({ chatHistory, loading }: ChatbotFeedProps) => {
  const chatFeedRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory, loading]);

  const scrollToBottom = () => {
    if (chatFeedRef.current) {
      chatFeedRef.current.scrollTop = chatFeedRef.current.scrollHeight;
    }
  };

  return (
    <div className="overflow-scroll pr-2 h-[80%] pb-8" ref={chatFeedRef}>
      <div className="flex flex-col gap-2 p-2">
        <div>
          <div className="flex justify-center items-center gap-2">
            <div className="p-2 rounded-full bg-gradient flex justify-center items-center">
              <IoIosHelpBuoy size={24} color="white" />
            </div>
            <div className="bg-creamWhite rounded-md shadow-md p-2">
              <p className="text-sm">
                Bonjour, je suis le chatbot Luter ! Je suis là pour répondre à
                vos questions.
              </p>
            </div>
          </div>
        </div>
        {chatHistory?.length > 0 &&
          chatHistory?.map((chat, index) => (
            <ChatbotFeedMessage
              key={index}
              reverse={chat.role === 'user'}
              message={chat}
            />
          ))}
        {loading && (
          <div className="ml-4">
            <ThreeDots
              visible={true}
              height="50"
              width="50"
              ariaLabel=""
              wrapperStyle={{}}
              wrapperClass=""
              color="#2850E7"
            />
            <p className="text-sm text-gray-500">
              Luter bot est entrain d&apos;écrire...
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export interface ChatbotFeedProps {
  chatHistory: chatHistory[];
  loading?: boolean;
}

export default ChatbotFeed;

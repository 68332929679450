import React, { useState } from 'react';
import { IconType } from 'react-icons';
import { FiInfo } from 'react-icons/fi';

interface TooltipProps {
  icon?: IconType;
  tooltipText: string;
}

const Tooltip: React.FC<TooltipProps> = ({
  icon: Icon = FiInfo,
  tooltipText,
}) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const handleMouseEnter = () => {
    setIsTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    setIsTooltipVisible(false);
  };

  return (
    <div className="relative inline-block">
      <div
        className="rounded-full bg-gradient text-white w-4 h-4 flex items-center justify-center"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Icon size={8} />
      </div>
      {isTooltipVisible && (
        <div className="absolute bg-creamWhite text-black shadow-xl p-2 rounded-md border border-primary text-sm bottom-full min-w-[20vw] left-1/2 transform -translate-x-1/2">
          {tooltipText}
        </div>
      )}
    </div>
  );
};

export default Tooltip;

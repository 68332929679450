import React, { useEffect, useState } from 'react';
import DashboardLayoutClient from '../../Layout/DashboardLayoutClient';
import { FaPen } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { MdEditOff } from 'react-icons/md';
import { TextPad } from '../../Components/CreateMissionSteps/TextPad';
import { ThreeDots } from 'react-loader-spinner';
import {
  useModificationProposalMutation,
  useSprintCheckoutMutation,
  useGetProjectByMissionIdQuery,
} from '../../redux/services/missions';
import displayWarningToast from '../../utils/displayWarningToast';
import displaySuccessToast from '../../utils/displaySuccessToast';
import { useToggleClientApprovalSprintMutation } from '../../redux/services/missions';
import { jwtDecode } from 'jwt-decode';

const ClientMissionDetails = () => {
  const mission = useSelector((state: any) => state.mission.mission);
  const token = new URLSearchParams(location.search).get('token');
  const decodedUser: any = jwtDecode(token as string);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [data, setData] = useState<any>();
  const [approvedSprints, setApprovedSprints] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modificationProposal] = useModificationProposalMutation();
  const [sprintCheckout] = useSprintCheckoutMutation();
  const { data: projectData } = useGetProjectByMissionIdQuery(mission.id);
  const [toggleClientApprovalSprint] = useToggleClientApprovalSprintMutation();

  useEffect(() => {
    if (!projectData) return;
    setData((projectData as any)[0] || {});
  }, [projectData]);

  const onClickToggleClientApprovalSprint = async (sprintId: number) => {
    await toggleClientApprovalSprint({ sprintId })
      .unwrap()
      .then(() => {
        displaySuccessToast('Sprint approuvé !');
        setApprovedSprints([...approvedSprints, sprintId]);
      })
      .catch(() => {
        displayWarningToast("Erreur lors de l'approbation du sprint !");
      });
  };

  const generateTaskStructure = (sprint: any, paymentMethods: any) => {
    const isSprintDone = sprint.sprintElements.every(
      (task: any) => task.done === true
    );

    console.log(projectData);
    return (
      <div
        className={`flex flex-col gap-4 ${
          isSprintDone ? 'bg-green-100' : ''
        } p-4 rounded-lg flex`}
      >
        <strong className="flex flex-col">
          Intitulé du sprint: {sprint.name}{' '}
          {isSprintDone ? (
            <span className="text-green-500">Terminé</span>
          ) : (
            <span className="text-red-500">En cours...</span>
          )}
        </strong>
        <div>
          <strong>Validation du sprint:</strong>
          <div className="flex justify-start items-center gap-2">
            <p>Freelance:</p>
            {sprint.freelance_approval ? (
              <p className="text-green-500">Approuvé</p>
            ) : (
              <p className="text-red-500">En attente...</p>
            )}
          </div>
          <div className="flex justify-start items-center gap-2">
            <p>Votre approbation:</p>
            {sprint.client_approval ? (
              <p className="text-green-500">Approuvé</p>
            ) : (
              <p className="text-red-500">En attente...</p>
            )}
          </div>
        </div>
        <p>
          <strong>Prix total (TTC):</strong> {sprint.price_ttc}€
        </p>
        {
          // find into sprint.transactions if there is a transaction with status valid or captured
          // if yes, display a button to pay the sprint
          // else, display a button to pay the sprint
          sprint.transactions &&
          sprint?.transactions?.length > 0 &&
          sprint.transactions.find(
            (transaction: any) =>
              transaction.status === 'valid' ||
              transaction.status === 'captured'
          ) ? (
            <p className="text-green-500">Payé</p>
          ) : (
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded h-10 align-middle disabled:opacity-80"
              onClick={() => handlePayment(sprint)}
              disabled={!isSprintDone}
            >
              Payer
            </button>
          )
        }
        {(isSprintDone || approvedSprints.includes(sprint.id)) &&
          !sprint.client_approval && (
            <button
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded h-10 align-middle disabled:opacity-80"
              onClick={() => onClickToggleClientApprovalSprint(sprint.id)}
            >
              Approuver le sprint
            </button>
          )}
        <br />
        <h1>
          <strong>Tâches:</strong>
        </h1>
        <ul>
          {sprint.sprintElements &&
            sprint.sprintElements.map((task: any, index: number) => (
              <div
                className={`flex flex-row center items-center`}
                key={task.id}
              >
                <li
                  className={`flex flex-col w-full text-mainBlack gap-4 m-2 border border-gray-300 rounded-xl p-4 ${
                    task.done ? 'bg-green-200' : ''
                  }`}
                >
                  <p className="font-bold text-lg">Tâche n°{index + 1}</p>
                  <div className="flex flex-col justify-between sm:flex-row">
                    <p>
                      <span className="font-bold">Description: </span>
                      {task.description
                        ? task.description
                        : 'Aucune description disponible'}
                    </p>
                    <p>
                      <span className="font-bold">Prix (TTC):</span>{' '}
                      {task.price_ttc}€
                    </p>
                    <div className="flex justify-center items-center gap-2">
                      <span className="font-bold">Terminé ?</span>{' '}
                      <input
                        className="h-5 w-5"
                        type="checkbox"
                        disabled
                        checked={task.done}
                      />
                    </div>
                  </div>
                </li>
              </div>
            ))}
        </ul>
      </div>
    );
  };

  const handlePayment = async (sprint: any) => {
    await sprintCheckout({ id: sprint.id })
      .unwrap()
      .then(async (res) => {
        window.open(
          `${import.meta.env.VITE_BASE_API_URL}sprint/checkout/${sprint.id}/${
            (res as any).checkout_token
          }?amount=${sprint.price_ttc}&product_name=${
            sprint.name
          }&product_description=${sprint.description}&quantity=${
            sprint?.sprintElements?.length
          }&payment_method=${paymentMethods}`,
          '_blank'
        );
        displaySuccessToast('Paiement effectué !');
      });
  };

  const numberOfSprints = data?.sprints ? data?.sprints?.length : 0;

  const paymentMethods =
    data?.projectPayments &&
    data?.projectPayments.map((payment: any) => payment.name).join(', ');

  const totalMissionPrice =
    data?.sprints &&
    data?.sprints.reduce(
      (total: number, sprint: any) => total + sprint.price_ttc,
      0
    );

  const handleSaveEdit = async () => {
    setIsLoading(true);
    modificationProposal({ body: data })
      .unwrap()
      .then((res: any) => {
        console.log(res);
        displaySuccessToast(
          'Proposition de modification envoyée ! En attente de validation par le freelance.'
        );
      })
      .catch((err) => {
        console.log(err);
        displayWarningToast(
          "Erreur lors de l'envoi de la proposition de modification !"
        );
      });
    setTimeout(() => {
      setIsEditing(!isEditing);
      setIsLoading(false);
    }, 2000);
  };

  const handleChangeEdit = (e: any) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  return (
    <DashboardLayoutClient>
      <div className="shadow-2xl bg-creamWhite rounded-[30px] min-h-[79vh] p-6 flex flex-col gap-4 border border-whiteGrey">
        <h1 className="text-3xl text-center font-bold text-mainBlack flex flex-col sm:flex-row justify-between items-center gap-4">
          Informations de la mission
          {isEditing ? (
            <div className="flex flex-col sm:flex-row gap-4">
              <button
                className="rounded-lg w-full disabled:opacity-80 min-w-[200px] bg-primary whitespace-nowrap px-4 py-2 text-sm text-white shadow-md transition-shadow duration-150 hover:shadow-none flex justify-center items-center gap-2"
                onClick={handleSaveEdit}
                disabled={isLoading}
              >
                {isLoading ? (
                  <ThreeDots height="15" width="15" color="white" />
                ) : (
                  <>
                    Confirmer les modifications <FaPen size={20} />
                  </>
                )}
              </button>
              <button
                className="rounded-lg whitespace-nowrap disabled:opacity-80 w-full bg-red-500 px-4 py-2 text-sm text-white shadow-md transition-shadow duration-150 hover:shadow-none flex justify-center items-center gap-2"
                onClick={() => setIsEditing(!isEditing)}
                disabled={isLoading}
              >
                Annuler les modifications <MdEditOff size={20} />
              </button>
            </div>
          ) : (
            <button
              className="rounded-lg bg-primary px-4 py-2 text-sm text-white shadow-md transition-shadow duration-150 hover:shadow-none flex justify-center items-center gap-2"
              onClick={() => setIsEditing(!isEditing)}
            >
              Proposer une modification <FaPen size={10} />
            </button>
          )}
        </h1>
        {isEditing ? (
          <div>
            <div className="my-2 flex flex-col sm:flex-row justify-start items-center gap-2">
              <h2 className="text-2xl font-bold text-mainBlack w-fit">
                Nom de la mission:
              </h2>
              <input
                type="text"
                placeholder="Nom de la mission"
                className="outline-none bg-white border p-2 rounded-lg"
                onChange={handleChangeEdit}
                name="name"
                value={data.name}
              />
            </div>
            <div className="bg-fullWhite text-mainBlack rounded-lg p-4">
              <p className="flex gap-2 justify-start items-center">
                <strong>Statut:</strong>{' '}
                {data?.status === 'in_progress'
                  ? 'En cours...'
                  : data?.status === 'done'
                  ? 'Terminé !'
                  : 'N/A'}
                <span
                  className={`w-2 h-2 rounded-full block ${
                    data?.status === 'in_progress'
                      ? 'bg-yellow-500'
                      : data?.status === 'completed'
                      ? 'bg-green-500'
                      : 'bg-red-500'
                  }`}
                ></span>
              </p>
              <p>
                <strong>Nombre de sprints:</strong> {numberOfSprints}
              </p>
              <p>
                <strong>Méthode(s) de paiement:</strong>{' '}
                <select
                  name="payment_method_ids"
                  id="payment_method_ids"
                  className="w-full outline-none bg-creamWhite"
                  onChange={handleChangeEdit}
                  value={data.projectPayments[0].name}
                >
                  <option value="progressive">Facturation progressive</option>
                  <option value="cryptocurrency">Cryptomonnaie</option>
                  <option value="esc">Séquestration de fonds</option>
                  <option value="row">Comptant</option>
                  <option value="bank_print">Empreinte bancaire</option>
                </select>
              </p>
            </div>
            <TextPad data={data} setData={setData} />
          </div>
        ) : (
          <div>
            <h2 className="text-2xl font-bold text-mainBlack my-2 ">
              Nom de la mission: {data?.name}
            </h2>
            <div className="bg-fullWhite text-mainBlack rounded-lg p-4">
              <p className="flex gap-2 justify-start items-center">
                <strong>Statut:</strong>{' '}
                {data?.status === 'in_progress'
                  ? 'En cours...'
                  : data?.status === 'done'
                  ? 'Terminé !'
                  : 'N/A'}
                <span
                  className={`w-2 h-2 rounded-full block ${
                    data?.status === 'in_progress'
                      ? 'bg-yellow-500'
                      : data?.status === 'completed'
                      ? 'bg-green-500'
                      : 'bg-red-500'
                  }`}
                ></span>
              </p>
              <p>
                <strong>Nombre de sprints:</strong> {numberOfSprints}
              </p>
              <p>
                <strong>Méthode(s) de paiement:</strong>{' '}
                {paymentMethods || 'N/A'}
              </p>
              <p>
                <strong>Prix total de la mission:</strong>{' '}
                {totalMissionPrice || 0}€
              </p>
            </div>
            <h2 className="text-2xl font-bold text-mainBlack mt-8">
              Description des différents sprints
            </h2>
            <div className="rounded-lg">
              {data?.sprints &&
                data?.sprints.map((sprint: any, index: any) => (
                  <div className="bg-fullWhite rounded-lg mb-4 p-4" key={index}>
                    {generateTaskStructure(sprint, paymentMethods)}
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </DashboardLayoutClient>
  );
};

export default ClientMissionDetails;

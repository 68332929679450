import DashboardLayout from '../../Layout/DashboardLayout';
import { useParams } from 'react-router-dom';
import React, { useState } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import displayWarningToast from '../../utils/displayWarningToast';
import displaySuccessToast from '../../utils/displaySuccessToast';
import {
  useAcceptProposalMutation,
  useRejectProposalMutation,
} from '../../redux/services/missions';

import {
  useGetProposalQuery,
  useGetMissionByIdQuery,
} from '../../redux/services/missions';
import ModificationProposalDifferences from './ModificationProposalDifferences';
import { FaCheckCircle } from 'react-icons/fa';
import { FaTimesCircle } from 'react-icons/fa';

const ModificationProposalDetail = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { id, proposalId } = useParams();
  const {
    data: modificationProposal,
    refetch,
    isLoading: isFetching,
  } = useGetProposalQuery({
    id: parseInt(id as string),
    proposalId: parseInt(proposalId as string),
  }) as any;

  const [acceptProposal, { isLoading: isAccepting }] =
    useAcceptProposalMutation();
  const [rejectProposal, { isLoading: isRejecting }] =
    useRejectProposalMutation();

  const handleAcceptProposal = async () => {
    setIsLoading(true);
    try {
      await acceptProposal({
        id: parseInt(id as string),
        proposalId: parseInt(proposalId as string),
      });
      displaySuccessToast('Proposition de modification acceptée !');
      refetch();
    } catch (err) {
      displayWarningToast(
        "Erreur lors de l'acceptation de la proposition de modification !"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleRejectProposal = async () => {
    setIsLoading(true);
    try {
      await rejectProposal({
        id: parseInt(id as string),
        proposalId: parseInt(proposalId as string),
      });
      displaySuccessToast('Proposition de modification rejetée !');
      refetch();
    } catch (err) {
      displayWarningToast(
        'Erreur lors du rejet de la proposition de modification !'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const { data: mission } = useGetMissionByIdQuery(
    parseInt(id as string)
  ) as any;

  return (
    <DashboardLayout>
      <div>
        <h1 className="text-3xl font-bold text-gray-900">
          Proposition de modification de la mission #{id || '0'}
        </h1>
        {isFetching ? (
          <div className="flex flex-col gap-4 justify-center items-center my-6">
            <p className="text-xl font-bold text-gray-900">
              Chargement des informations de la mission
            </p>
            <ThreeDots color="#2563EB" height={50} width={50} />
          </div>
        ) : (
          <div>
            <p className="flex gap-2 justify-start items-center">
              <strong>Statut:</strong>{' '}
              {modificationProposal?.status === 'pending'
                ? 'En attente'
                : modificationProposal?.status === 'accepted'
                ? 'Accepté !'
                : 'Rejeté !'}
              <span
                className={`w-2 h-2 rounded-full block ${
                  modificationProposal?.status === 'pending'
                    ? 'bg-yellow-500'
                    : modificationProposal?.status === 'accepted'
                    ? 'bg-green-500'
                    : 'bg-red-500'
                }`}
              ></span>
            </p>
            {modificationProposal?.status === 'pending' && (
              <div className="flex flex-col sm:flex-row justify-center items-start w-fit gap-4 my-4">
                <button
                  className="rounded-lg bg-primary px-4 py-2 text-sm text-white shadow-md transition-shadow duration-150 hover:shadow-none flex justify-center items-center gap-2 min-w-[250px]"
                  onClick={() => handleAcceptProposal()}
                  disabled={isLoading}
                >
                  Accepter les modifications <FaCheckCircle size={20} />
                </button>
                <button
                  className="rounded-lg whitespace-nowrap disabled:opacity-80 w-full bg-red-500 px-4 py-2 text-sm text-white shadow-md transition-shadow duration-150 hover:shadow-none flex justify-center items-center gap-2"
                  onClick={() => handleRejectProposal()}
                  disabled={isLoading}
                >
                  Annuler les modifications <FaTimesCircle size={20} />
                </button>
              </div>
            )}
            <h3 className="text-2xl font-bold text-gray-900">
              Champs modifiés par le client:
            </h3>
            <div className="mt-6 flex flex-col lg:flex-row gap-4">
              <ModificationProposalDifferences
                initial={mission?.[0]}
                updated={modificationProposal?.modificationProposal}
              />
            </div>
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};

export default ModificationProposalDetail;

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ClientNavbar from '../Components/ClientNavbar';
import SidebarClient from '../Components/SidebarClient';
import { jwtDecode } from 'jwt-decode'; // Corrected import statement
import { useSelector } from 'react-redux';
import { useGetProjectByMissionIdQuery } from '../redux/services/missions';

type Props = {
  children: any;
};

const DashboardLayoutClient: React.FC<Props> = ({ children }) => {
  const location = useLocation();
  const user = useSelector((state: any) => state.user.user);
  const [mission, setMission] = useState<any>(null);

  useEffect(() => {
    const extractUserInfoFromToken = () => {
      const token = new URLSearchParams(location.search).get('token');
      if (token) {
        const decodedUser = jwtDecode(token); // Call jwtDecode directly
        setMission(decodedUser);
      }
    };

    extractUserInfoFromToken();
  }, []);

  const { data: projectData, isLoading } = useGetProjectByMissionIdQuery(
    mission?.projectId
  );

  if (isLoading) {
    return (
      <main className="min-h-screen z-0 relative">
        <ClientNavbar />
        <div className="flex justify-start w-full h-screen bg-creamWhite">
          <SidebarClient username={'Chargement...'} missionId={mission?.id} />
          <div className="mt-32 w-full pr-8">{children}</div>
        </div>
      </main>
    );
  }

  const clientusername =
    ((projectData as any[])?.[0]?.projectUsers?.[0]?.user?.first_name || '') +
    ' ' +
    ((projectData as any[])?.[0]?.projectUsers?.[0]?.user?.last_name || '');

  return (
    <main className="min-h-screen z-0 relative">
      <ClientNavbar />

      <div className="flex justify-start w-full h-screen bg-creamWhite">
        <SidebarClient username={clientusername} missionId={mission?.id} />
        <div className="mt-32 w-full pr-8">{children}</div>
      </div>
    </main>
  );
};

export default DashboardLayoutClient;

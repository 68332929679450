import React, { useState, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Icon
import { MdEdit, MdSave } from 'react-icons/md';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { FiLinkedin, FiFacebook, FiGithub } from 'react-icons/fi';
import { FaXTwitter } from 'react-icons/fa6';
import { BsGeoAlt } from 'react-icons/bs';

// Components
import SettingsMenu from '../../../Components/Dashboard/SettingsMenu';
import SettingsMenuSmall from '../../../Components/Dashboard/SettingsMenuSmall';
import EditableField from '../../../Components/Dashboard/EditableField';
import DashboardLayout from '../../../Layout/DashboardLayout';
import { updateUser } from '../../../redux/feature/userSlice';

const CHARTER_INFO_TEXT =
  "La charte permet d'assurer au client de vérifier votre honnêteté.";

// type Section = {
//   label: string;
//   renderContent: () => React.ReactNode;
// };

const SettingsID: React.FC = () => {
  const user = useSelector((state: any) => state.user.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [state, setState] = useState({
    firstName: user.first_name,
    job: user.job,
    experience: user.experience,
    lastName: user.last_name,
    city: user.city,
    pseudo: user.pseudo,
    pseudoTwitter: user.pseudoTwitter,
    pseudoGithub: user.pseudoGithub,
    pseudoLinkedin: user.pseudoLinkedin,
    pseudoFacebook: user.pseudoFacebook,
  });

  // const sections: Section[] = [
  //   {
  //     label: 'Informations personnelles',
  //     renderContent: () => (
  //       <div>
  //         <EditableField
  //           label="Prénom"
  //           value={state.firstName}
  //           isEditMode={isEditMode}
  //           onChange={handleInputChange('firstName')}
  //           placeholder={'John'}
  //         />
  //         {/* ... autres champs ... */}
  //       </div>
  //     ),
  //   },
  //   // ... autres sections ...
  // ];

  const [isEditMode, setIsEditMode] = useState(false);
  const [isEditMode2, setIsEditMode2] = useState(false);
  const [isEditMode3, setIsEditMode3] = useState(false);
  const [profileImage, setProfileImage] = useState<string | ArrayBuffer | null>(
    null
  );
  const [infoText, setInfoText] = useState('');
  const [infoText2, setInfoText2] = useState('');
  const [infoText3, setInfoText3] = useState('');
  const [activeSocialLink, setActiveSocialLink] = useState<string>('');
  const [isAvailable, setIsAvailable] = useState(false);

  // handle field changes
  const handleSaveClick = () => {
    dispatch(
      updateUser({
        ...user,
        first_name: state.firstName,
        job: state.job,
        experience: state.experience,
        last_name: state.lastName,
        city: state.city,
        pseudo: state.pseudo,
        pseudoTwitter: state.pseudoTwitter,
        pseudoGithub: state.pseudoGithub,
        pseudoLinkedin: state.pseudoLinkedin,
        pseudoFacebook: state.pseudoFacebook,
      })
    );
    setIsEditMode(false);
    setIsEditMode2(false);
    setIsEditMode3(false);
    toast.success('Les données ont été sauvegardées avec succès!', {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });
  };
  const handleInputChange =
    (key: string) => (e: ChangeEvent<HTMLInputElement>) => {
      setState((prevState) => ({ ...prevState, [key]: e.target.value }));
    };
  const handleEditClick = () => {
    setIsEditMode(true);
  };

  const handleEditClick2 = () => {
    setIsEditMode2(true);
  };
  const handleEditClick3 = () => {
    setIsEditMode3(true);
  };
  const handleMouseEnter = () => {
    setInfoText(CHARTER_INFO_TEXT);
  };
  const handleMouseLeave = () => {
    setInfoText('');
  };
  const handleMouseEnter2 = () => {
    setInfoText('Informations supplémentaires');
  };
  const handleMouseLeave2 = () => {
    setInfoText('');
  };
  const handleMouseEnter3 = () => {
    setInfoText('Informations supplémentaires');
  };
  const handleMouseLeave3 = () => {
    setInfoText('');
  };
  const handleAvailableClick = () => {
    setIsAvailable(!isAvailable);
  };
  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSocialIconClick = (socialNetwork: string): void => {
    let socialLink = '';
    switch (socialNetwork) {
      case 'linkedin':
        socialLink = 'linkedin.com/';
        break;
      case 'twitter':
        socialLink = 'twitter.com/';
        break;
      case 'facebook':
        socialLink = 'facebook.com/';
        break;
      case 'github':
        socialLink = 'github.com/';
        break;
      default:
        socialLink = '';
    }
    setActiveSocialLink(socialLink);
  };

  // isValid
  const isPseudoValid = (value: string) => {
    const pseudoPattern = /^[a-zA-Z0-9]{6,}$/;
    return pseudoPattern.test(value);
  };
  const isFullnameValid = (value: string) => {
    const fullnamePattern = /^[a-zA-Z0-9\s]{6,}$/;
    return fullnamePattern.test(value);
  };

  const containerStyle = {
    border: '2px solid ' + (isAvailable ? 'green' : 'red'),
    borderRadius: '32px',
    padding: '4px',
    display: 'flex',
    alignItems: 'center',
    width: '140px',
    cursor: 'pointer',
  };

  const dotStyle = {
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    backgroundColor: isAvailable ? 'green' : 'red',
    marginRight: '10px',
  };

  return (
    <DashboardLayout>
      <div className="flex">
        <div className="w-full p-4">
          <div className="flex flex-row mb-10">
            <h1 className="text-left text-4xl font-bold bold-4 w-1/2 text-left">
              Paramètres du profil
            </h1>
            <div className="w-1/2 text-right">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                onClick={() => navigate('/profile')}
              >
                Voir votre profil publique
              </button>
            </div>
          </div>
          <div className="md:hidden xs:block w-1/4 pr-4">
            <SettingsMenuSmall />
          </div>
          <div className="flex h-full overflow-y-auto shadow-2xl bg-creamWhite rounded-[30px] p-6 xs:-mr-8 md:mr-0">
            <div className="md:block xs:hidden w-1/4 pr-4">
              <SettingsMenu />
            </div>
            <div className="xs:hidden md:block w-px bg-gray-300 mx-4" />

            {/* Partie droite de la div */}
            <div className="flex-grow">
              <div className="flex flex-row">
                <div className="space-x-4 mb-5 items-center">
                  <div className="w-200 h-200 rounded-full overflow-hidden">
                    {profileImage ? (
                      <img
                        src={profileImage?.toString()}
                        alt="Profile"
                        className="w-200 h-200 object-cover object-center"
                      />
                    ) : (
                      <img
                        src={'../src/assets/randomPP.svg'}
                        alt=""
                        className="w-200 h-200 object-cover object-center"
                      />
                    )}
                  </div>
                  <label
                    htmlFor="imageUpload"
                    className="cursor-pointer text-blue-500 pl-14"
                  >
                    Modifier l&apos;image
                  </label>
                  <input
                    type="file"
                    id="imageUpload"
                    accept="image/*"
                    className="hidden"
                    onChange={handleImageChange}
                  />
                </div>
                <div className="m-10">
                  <h1 className="font-bold text-4xl mb-3">
                    {user.first_name + ' ' + user.last_name}
                  </h1>
                  <h2 className="font-bold text-2xl text-blue-500 mb-3">
                    {user.job}
                  </h2>
                  <div className="flex text-blue-500 font-bold space-x-2 text-2xl mb-3">
                    <BsGeoAlt className="mt-1" />
                    <h2>{user.city}</h2>
                  </div>
                </div>
              </div>

              {/* Section 1 */}
              <div className="w-full overflow-y-auto border-2 bg-creamWhite rounded-[5px] p-4">
                <div className="flex items-center justify-between">
                  <h1 className="font-bold text-3xl mt-4">
                    Informations personnelles
                  </h1>
                  {isEditMode ? (
                    <button
                      onClick={handleSaveClick}
                      className="flex items-center justify-center space-x-2 hover:bg-blue-500 text-blue-500 hover:text-white rounded-[20px] p-2"
                    >
                      <MdSave className="text-darkCyan" />
                      <p>Sauvegarder</p>
                    </button>
                  ) : (
                    <button
                      onClick={handleEditClick}
                      className="flex items-center justify-center space-x-2 hover:bg-blue-500 text-blue-500 hover:text-white rounded-[20px] p-2"
                    >
                      <MdEdit className="text-darkCyan" />
                      <p>Modifier</p>
                    </button>
                  )}
                </div>
                <h3 className="text-blue-500 ml-5">
                  Nous gardons vos données privées et ne les partageons pas avec
                  des tiers
                </h3>
                <hr className="my-2" />
                {/* Mode d'édition */}
                {isEditMode ? (
                  <div className="flex space-x-4">
                    <div className="w-1/2">
                      <EditableField
                        label="Prénom"
                        value={state.firstName}
                        isEditMode={isEditMode}
                        onChange={handleInputChange('firstName')}
                        placeholder={'John'}
                      />
                      <EditableField
                        label="Profession"
                        value={state.job}
                        isEditMode={isEditMode}
                        onChange={handleInputChange('job')}
                        placeholder={'Développeur Back-End'}
                      />
                      <EditableField
                        label="Expérience"
                        value={state.experience}
                        isEditMode={isEditMode}
                        onChange={handleInputChange('experience')}
                      />
                    </div>
                    <div className="w-1/2">
                      <EditableField
                        label="Nom de famille"
                        value={state.lastName}
                        isEditMode={isEditMode}
                        onChange={handleInputChange('lastName')}
                        placeholder={'Doe'}
                      />
                      <EditableField
                        label="Ville"
                        value={state.city}
                        isEditMode={isEditMode}
                        onChange={handleInputChange('city')}
                      />
                      <div
                        className="mt-4"
                        onClick={handleAvailableClick}
                        style={containerStyle}
                      >
                        <div style={dotStyle}></div>
                        <p
                          className={
                            isAvailable
                              ? 'text-green-500 text-md'
                              : 'text-red-500 text-md'
                          }
                        >
                          {isAvailable ? 'Disponible' : 'Indisponible'}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  // Mode de visualisation
                  <div className="flex space-x-4">
                    <div className="w-1/2">
                      <p className="text-blue-500">Prénom</p>
                      <p className="font-bold">{state.firstName}</p>
                      <p className="text-blue-500">Profession</p>
                      <p className="font-bold">{user.job}</p>
                      <p className="text-blue-500">Expérience</p>
                      <p className="font-bold">{user.experience} ans</p>
                    </div>
                    <div className="w-1/2">
                      <p className="text-blue-500">Nom de famille</p>
                      <p className="font-bold">{user.last_name}</p>
                      <p className="text-blue-500">Ville</p>
                      <p className="font-bold">{user.city}</p>
                      <div className="mt-4" style={containerStyle}>
                        <div style={dotStyle}></div>
                        <p
                          className={
                            isAvailable
                              ? 'text-green-500 text-md'
                              : 'text-red-500 text-md'
                          }
                        >
                          {isAvailable ? 'Disponible' : 'Indisponible'}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {/* Section 2 */}
              <div className="mt-5 w-full overflow-y-auto border-2 bg-creamWhite rounded-[5px] p-4">
                <div className="flex items-center justify-between">
                  <h1 className="font-bold text-3xl mt-4">Profil public</h1>
                  {isEditMode2 ? (
                    <button
                      onClick={handleSaveClick}
                      className="flex items-center justify-center space-x-2 hover:bg-blue-500 text-blue-500 hover:text-white rounded-[20px] p-2"
                    >
                      <MdSave className="text-darkCyan" />
                      <p>Sauvegarder</p>
                    </button>
                  ) : (
                    <button
                      onClick={handleEditClick2}
                      className="flex items-center justify-center space-x-2 hover:bg-blue-500 text-blue-500 hover:text-white rounded-[20px] p-2"
                    >
                      <MdEdit className="text-darkCyan" />
                      <p>Modifier</p>
                    </button>
                  )}
                </div>
                <h3 className="text-blue-500 ml-5">
                  Les modifications apportées seront visibles sur votre profil
                  public
                </h3>
                <hr className="my-2" />
                {/* Mode d'édition */}
                {isEditMode2 ? (
                  <div className="flex space-x-4 justify-center">
                    <div className="w-2/2">
                      <label className="flex flex-col font-light relative md:mr-10">
                        Nom affiché sur votre profil public
                        <div className="flex items-center text-lg font-bold ">
                          <input
                            type="text"
                            placeholder={user.first_name + ' ' + user.last_name}
                            defaultValue={
                              state.firstName + ' ' + state.lastName
                            }
                            onChange={handleInputChange('lastName')}
                            className="w-full md:w-80 text-center border border-black border-2 rounded-md p-2 -ml-0.5"
                          />
                          {isFullnameValid(state.lastName) ? (
                            <span className="text-green-700 p-2 rounded-full ml-2">
                              ✓
                            </span>
                          ) : (
                            <span className="text-red-700 p-2 rounded-full ml-2">
                              ✗
                            </span>
                          )}
                        </div>
                      </label>
                      <label className="flex flex-col font-light relative md:mr-10">
                        Lien vers votre profil public
                        <div className="flex items-center text-lg font-bold ">
                          <span className="bg-blue-500 text-white border-black border-2 p-2 rounded-l-md">
                            luter.fr/
                          </span>
                          <input
                            type="text"
                            placeholder={'john-doe'}
                            defaultValue={state.pseudo}
                            onChange={handleInputChange('pseudo')}
                            className="w-full md:w-60 text-center border border-black border-2 rounded-r-md p-2 -ml-0.5"
                          />
                          {isPseudoValid(state.pseudo) ? (
                            <span className="text-green-700 p-2 rounded-full ml-2">
                              ✓
                            </span>
                          ) : (
                            <span className="text-red-700 p-2 rounded-full ml-2">
                              ✗
                            </span>
                          )}
                        </div>
                      </label>
                    </div>
                  </div>
                ) : (
                  // Mode de visualisation
                  <div className="flex space-x-4 justify-center">
                    <div className="w-2/2">
                      <label className="flex flex-col font-light relative md:mr-10">
                        Nom affiché sur votre profil public
                        <div className="flex items-center text-lg font-bold ">
                          <p className="w-full md:w-80 text-center border border-black border-2 rounded-md p-2 -ml-0.5">
                            {user.first_name} {user.last_name}
                          </p>
                        </div>
                      </label>
                      <label className="flex flex-col font-lightrelative md:mr-10">
                        Lien vers votre profil public
                        <div className="flex items-center text-lg font-bold ">
                          <span className="bg-blue-500 text-white border-black border-2 p-2 rounded-l-md">
                            luter.fr/
                          </span>
                          <p
                            className="w-full md:w-60 text-center border border-black border-2 rounded-r-md p-2 -ml-0.5"
                            placeholder="john-doe2"
                          >
                            {state.pseudo}
                          </p>
                        </div>
                      </label>
                    </div>
                  </div>
                )}
              </div>

              {/* Section 3 */}
              <div className="mt-5 w-full overflow-y-auto border-2 bg-creamWhite rounded-[5px] p-4">
                <div className="flex items-center justify-between">
                  <h1 className="font-bold text-3xl mt-4">Vérifications</h1>
                </div>
                <h3 className="text-blue-500 ml-5">
                  Veillez à ce que vos vérifications soient toujours à jour
                </h3>
                <hr className="my-5" />
                <div className="flex space-x-4 justify-center">
                  <div className="bg-green-200 rounded-2xl p-4 w-3/4 flex justify-between items-center">
                    <div className="flex flex-row">
                      <h2 className="font-bold text-gray-700 text-lg">
                        Charte du freelance Luter signée
                      </h2>
                      <span className="text-green-700 rounded-full ml-2">
                        ✓
                      </span>
                    </div>
                    <div className="flex items-center">
                      <div className="cursor-pointer bg-transparent hover:bg-cyan-500 text-cyan-700 font-semibold hover:text-white border border-cyan-500 hover:border-transparent rounded px-2 py-1">
                        Consulter la charte
                      </div>
                      <div className="text-cyan-700 hover:text-blue-500 ml-2 relative flex items-center">
                        <AiOutlineInfoCircle
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                        />
                        {infoText && (
                          <div className="z-90 info-tooltip absolute bg-white p-2 rounded border border-gray-300 ml-5">
                            {infoText}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex space-x-4 justify-center mt-5">
                  <div className="bg-green-200 rounded-2xl p-4 w-3/4 flex justify-between items-center">
                    <div className="flex flex-row">
                      <h2 className="font-bold text-gray-700 text-lg">
                        Entreprise validée
                      </h2>
                      <span className="text-green-700 rounded-full ml-2">
                        ✓
                      </span>
                    </div>
                    <div className="flex items-center">
                      <div className="cursor-pointer bg-transparent hover:bg-cyan-500 text-cyan-700 font-semibold hover:text-white border border-cyan-500 hover:border-transparent rounded px-2 py-1">
                        Vérifiez votre entreprise
                      </div>
                      <div className="text-cyan-700 hover:text-blue-500 ml-2 relative z-10">
                        <AiOutlineInfoCircle
                          onMouseEnter={handleMouseEnter2}
                          onMouseLeave={handleMouseLeave2}
                        />
                        {infoText2 && (
                          <div className="info-tooltip absolute bg-white p-2 rounded border border-gray-300 mt-2">
                            {infoText2}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex space-x-4 justify-center mt-5">
                  <div className="bg-red-200 rounded-2xl p-4 w-3/4 flex justify-between items-center">
                    <div className="flex flex-row">
                      <h2 className="font-bold text-gray-700 text-lg">
                        Documents d’identité à jour
                      </h2>
                      <span className="text-red-700 rounded-full ml-2">✗</span>
                    </div>
                    <div className="flex items-center">
                      <div className="cursor-pointer bg-transparent hover:bg-cyan-500 text-cyan-700 font-semibold hover:text-white border border-cyan-500 hover:border-transparent rounded px-2 py-1">
                        Vérifiez vos documents
                      </div>
                      <div className="text-cyan-700 hover:text-blue-500 ml-2 relative">
                        <AiOutlineInfoCircle
                          onMouseEnter={handleMouseEnter3}
                          onMouseLeave={handleMouseLeave3}
                        />
                        {infoText3 && (
                          <div className="info-tooltip absolute bg-white p-2 rounded border border-gray-300 mt-2">
                            {infoText3}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Section 4 */}
              <div className="mt-5 w-full overflow-y-auto border-2 bg-creamWhite rounded-[5px] p-4">
                <div className="flex items-center justify-between">
                  <h1 className="font-bold text-3xl mt-4">Réseaux sociaux</h1>
                  {isEditMode3 ? (
                    <button
                      onClick={handleSaveClick}
                      className="flex items-center justify-center space-x-2 hover:bg-blue-500 text-blue-500 hover:text-white rounded-[20px] p-2"
                    >
                      <MdSave className="text-darkCyan" />
                      <p>Sauvegarder</p>
                    </button>
                  ) : (
                    <button
                      onClick={handleEditClick3}
                      className="flex items-center justify-center space-x-2 hover:bg-blue-500 text-blue-500 hover:text-white rounded-[20px] p-2"
                    >
                      <MdEdit className="text-darkCyan" />
                      <p>Modifier</p>
                    </button>
                  )}
                </div>
                <h3 className="text-blue-500 ml-5">
                  Cliquez sur un logo pour éditer le lien
                </h3>
                <hr className="my-2" />
                {/* Mode d'édition */}
                {isEditMode3 ? (
                  <div className="flex space-x-4 justify-center">
                    <div className="w-2/2">
                      <div className="flex space-x-5 justify-center m-5">
                        <FiLinkedin
                          size={40}
                          className="cursor-pointer text-black hover:text-blue-300 -ml-5"
                          onClick={() => handleSocialIconClick('linkedin')}
                        />
                        <FaXTwitter
                          size={40}
                          className="cursor-pointer text-black hover:text-blue-300"
                          onClick={() => handleSocialIconClick('twitter')}
                        />
                        <FiFacebook
                          size={40}
                          className="cursor-pointer text-black hover:text-blue-300"
                          onClick={() => handleSocialIconClick('facebook')}
                        />
                        <FiGithub
                          size={40}
                          className="cursor-pointer text-black hover:text-blue-300"
                          onClick={() => handleSocialIconClick('github')}
                        />
                      </div>

                      <label className="flex flex-col font-light text-blue-500 relative md:mr-10">
                        <div className="flex items-center text-lg font-bold ">
                          <span className="bg-blue-500 text-white border-black border-2 p-2 rounded-l-md">
                            {activeSocialLink
                              ? activeSocialLink
                              : 'linkedin.com/'}
                          </span>
                          <input
                            type="text"
                            placeholder={
                              activeSocialLink === 'linkedin.com/'
                                ? user.pseudoLinkedin
                                : activeSocialLink === 'twitter.com/'
                                ? user.pseudoTwitter
                                : activeSocialLink === 'facebook.com/'
                                ? user.pseudoFacebook
                                : activeSocialLink === 'github.com/'
                                ? user.pseudoGithub
                                : 'pseudo'
                            }
                            defaultValue={
                              activeSocialLink === 'linkedin.com/'
                                ? state.pseudoLinkedin
                                : activeSocialLink === 'twitter.com/'
                                ? state.pseudoTwitter
                                : activeSocialLink === 'facebook.com/'
                                ? state.pseudoFacebook
                                : activeSocialLink === 'github.com/'
                                ? state.pseudoGithub
                                : 'pseudo'
                            }
                            onChange={
                              activeSocialLink === 'linkedin.com/'
                                ? handleInputChange('pseudoLinkedin')
                                : activeSocialLink === 'twitter.com/'
                                ? handleInputChange('pseudoTwitter')
                                : activeSocialLink === 'facebook.com/'
                                ? handleInputChange('pseudoFacebook')
                                : activeSocialLink === 'github.com/'
                                ? handleInputChange('pseudoGithub') ||
                                  'john-doe-gt'
                                : handleInputChange('pseudoGithub')
                            }
                            className="w-full md:w-60 text-center border border-black border-2 rounded-r-md p-2 -ml-0.5"
                          />
                          {isPseudoValid(state.pseudo) ? (
                            <span className="text-green-700 p-2 rounded-full ml-2">
                              ✓
                            </span>
                          ) : (
                            <span className="text-red-700 p-2 rounded-full ml-2">
                              ✗
                            </span>
                          )}
                        </div>
                      </label>
                    </div>
                  </div>
                ) : (
                  // Mode de visualisation
                  <div className="flex space-x-4 justify-center">
                    <div className="w-2/2">
                      <div className="flex space-x-5 justify-center m-5">
                        <FiLinkedin
                          size={40}
                          className="cursor-pointer text-black hover:text-blue-300 -ml-5"
                          onClick={() => handleSocialIconClick('linkedin')}
                        />
                        <FaXTwitter
                          size={40}
                          className="cursor-pointer text-black hover:text-blue-300"
                          onClick={() => handleSocialIconClick('twitter')}
                        />
                        <FiFacebook
                          size={40}
                          className="cursor-pointer text-black hover:text-blue-300"
                          onClick={() => handleSocialIconClick('facebook')}
                        />
                        <FiGithub
                          size={40}
                          className="cursor-pointer text-black hover:text-blue-300"
                          onClick={() => handleSocialIconClick('github')}
                        />
                      </div>

                      <label className="flex flex-col font-light text-blue-500 relative md:mr-10">
                        <div className="flex items-center text-lg font-bold ">
                          <span className="bg-blue-500 text-white border-black border-2 p-2 rounded-l-md">
                            {activeSocialLink
                              ? activeSocialLink
                              : 'linkedin.com/'}
                          </span>
                          <p className="w-full md:w-60 text-center border border-black border-2 rounded-r-md p-2 -ml-0.5">
                            {activeSocialLink === 'linkedin.com/'
                              ? user.pseudoLinkedin || 'john-doe-lkdn'
                              : activeSocialLink === 'twitter.com/'
                              ? user.pseudoTwitter || 'john-doe-tw'
                              : activeSocialLink === 'facebook.com/'
                              ? user.pseudoFacebook || 'john-doe-fb'
                              : activeSocialLink === 'github.com/'
                              ? user.pseudoGithub || 'john-doe-github'
                              : 'not found'}
                          </p>
                        </div>
                      </label>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default SettingsID;

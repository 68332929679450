import React, { useState, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Icon
import { MdEdit, MdSave } from 'react-icons/md';

// Components
import SettingsMenu from '../../Components/Dashboard/SettingsMenu';
import Button from '../../Components/Button';
import DashboardLayout from '../../Layout/DashboardLayout';
import SettingsMenuSmall from '../../Components/Dashboard/SettingsMenuSmall';
import EditableField from '../../Components/Dashboard/EditableField';
import { updateUser } from '../../redux/feature/userSlice';

const SettingsSecurity: React.FC = () => {
  const dummyProfiles = [
    {
      id: 1,
      email: 'johndoe@internet.com',
      phone: '+33 6 12 34 56 78',
      lastConnections: 'aujourd’hui à 18:34, Chrome 198.123.23.23',
      activeSessions: [
        ['DESKTOP-6TIG6EC • Tourcoing, France', 'Chrome • Actif en ce moment'],
        ['Iphone 11 • Tourcoing, France', 'Safari • 18/06/2023'],
        ['MAC-484284 • Paris, France', 'Safari • 07/04/2023'],
      ],
    },
  ];
  // useState
  const user = useSelector((state: any) => state.user.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [state, setState] = useState({
    email: user.email,
    phone: user.phone,
  });
  const [isEditMode, setIsEditMode] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const nombreDeSessionsActives = dummyProfiles[0].activeSessions.length;
  const handleEditClick = () => {
    setIsEditMode(true);
  };

  const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(email);
  };

  const isValidPhone = (phone: string): boolean => {
    const phoneRegex = /^\+\d{11,12}$/;
    return phoneRegex.test(phone);
  };

  const handleInputChange =
    (key: string) => (e: ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value;
      setState((prevState) => ({ ...prevState, [key]: inputValue }));
    };

  const handleSaveClick = () => {
    const isEmailValid = isValidEmail(state.email);
    const isPhoneValid = isValidPhone(state.phone);

    if (isEmailValid && isPhoneValid) {
      dispatch(
        updateUser({
          ...user,
          email: state.email,
          phone: state.phone,
        })
      );
      setIsEditMode(false);
      toast.success('Les données ont été sauvegardées avec succès!', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
    if (!isPhoneValid) {
      toast.error('Numéro de téléphone invalide', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
      console.error('Numéro de téléphone invalide');
    } else if (!isEmailValid) {
      toast.error('Adresse email invalide', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
      console.error('Adresse email invalide');
    }
  };

  return (
    <DashboardLayout>
      <div className="flex">
        <div className="w-full p-4">
          <div className="flex flex-row mb-10">
            <h1 className="text-left text-4xl font-bold bold-4 w-1/2 text-left">
              Paramètres du profil
            </h1>
            <div className="w-1/2 text-right">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                onClick={() => navigate('/profile')}
              >
                Voir votre profil publique
              </button>
            </div>
          </div>
          <div className="md:hidden xs:block w-1/4 pr-4">
            <SettingsMenuSmall />
          </div>
          <div className="flex h-full overflow-y-auto shadow-2xl bg-creamWhite rounded-[30px] p-6 xs:-mr-8 md:mr-0">
            <div className="md:block xs:hidden w-1/4 pr-4">
              <SettingsMenu />
            </div>
            <div className="xs:hidden md:block w-px bg-gray-300 mx-4" />

            <div className="w-full overflow-y-auto border-2 bg-creamWhite rounded-[5px] p-4">
              <div className="flex items-center justify-between">
                <h1 className="font-bold text-3xl mt-4">Sécurité</h1>
                {isEditMode ? (
                  <div className="flex items-center justify-center space-x-2 hover:bg-blue-500 text-blue-500 hover:text-white rounded-[20px] p-2">
                    <MdSave className="text-darkCyan" />
                    <button onClick={handleSaveClick}>Sauvegarder</button>
                  </div>
                ) : (
                  <div className="flex items-center justify-center space-x-2 hover:bg-blue-500 text-blue-500 hover:text-white rounded-[20px] p-2">
                    <MdEdit className="text-darkCyan" />
                    <button onClick={handleEditClick}>Modifier</button>
                  </div>
                )}
              </div>
              <h3 className="text-blue-500 ml-5">
                Nous gardons vos données privées
              </h3>
              <hr className="my-2" />
              {/* Mode d'édition */}
              {isEditMode ? (
                <div className="flex mt-10">
                  <div className="w-1/2 space-y-5 text-left mx-20">
                    <p className="text-blue-500">E-mail de connexion</p>
                    <p className="text-blue-500">Mot de passe</p>
                    <p className="text-blue-500">
                      Authentification à 2 facteurs
                    </p>
                    <p className="text-blue-500">Numéro de téléphone</p>
                  </div>
                  <div className="w-1/2 space-y-5 text-right">
                    <input
                      className="w-full md:w-60 text-center border border-black border-2 rounded-md mx-20 text-right"
                      type="text"
                      value={state.email}
                      onChange={handleInputChange('email')}
                      placeholder={'john.doe@gmail.com'}
                    />
                    <button
                      onClick={() => navigate('/reset-password/email-recovery')}
                      className="font-bold mx-20 text-blue-500 cursor-pointer"
                    >
                      Changer le mot de passe
                    </button>
                    <label className="inline-flex relative items-center mx-20 cursor-pointer">
                      <input
                        type="checkbox"
                        className="sr-only peer"
                        checked={enabled}
                        readOnly
                      />
                      <div
                        onClick={() => {
                          setEnabled(!enabled);
                        }}
                        className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-blue-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"
                      ></div>
                    </label>
                    <input
                      className="w-full md:w-60 text-center border border-black border-2 rounded-md mx-20 text-right"
                      type="text"
                      value={state.phone}
                      onChange={handleInputChange('phone')}
                      placeholder={'+33 6 12 34 56 78'}
                    />
                  </div>
                </div>
              ) : (
                // Mode de visualisation
                <div className="flex mt-10">
                  <div className="w-1/2 space-y-5 text-left mx-20">
                    <p className="text-blue-500">E-mail de connexion</p>
                    <p className="text-blue-500">Mot de passe</p>
                    <p className="text-blue-500">
                      Authentification à 2 facteurs
                    </p>
                    <p className="text-blue-500">Numéro de téléphone</p>
                    <hr />
                    <p className="text-blue-500 font-bold">
                      Dernières connections
                    </p>
                    <p className="">{dummyProfiles[0].lastConnections}</p>
                    <p className="text-blue-500 font-bold">
                      Total des sessions actives ({nombreDeSessionsActives})
                    </p>
                    {dummyProfiles[0].activeSessions.map((session, index) => (
                      <div key={index}>
                        <p>{session[0]}</p>
                        <p className="text-cyan-700 text-sm">{session[1]}</p>
                        {index !== nombreDeSessionsActives - 1 && (
                          <hr className="my-2" />
                        )}
                      </div>
                    ))}
                  </div>
                  <div className="w-1/2 space-y-5 text-right">
                    <p className="font-bold mx-20">{user.email}</p>
                    <p className="font-bold mx-20">***********</p>
                    <label className="inline-flex relative items-center mx-20 cursor-pointer">
                      <input
                        type="checkbox"
                        className="sr-only peer"
                        checked={enabled}
                        readOnly
                      />
                      <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-blue-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                    </label>
                    <p className="font-bold mx-20">{user.phone}</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default SettingsSecurity;

import DashboardLayout from '../../Layout/DashboardLayout';
import { useParams } from 'react-router-dom';
import MOCK_DOCUMENT from '../../Components/molecules/FACTURE.pdf';
import PDFViewer from '../../Components/molecules/PDFViewer';
import React, { useMemo, useRef, useState } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import DocumentSignature from '../../Components/molecules/DocumentSignature';
import SignatureCanvas from 'react-signature-canvas';
import displayWarningToast from '../../utils/displayWarningToast';
import displaySuccessToast from '../../utils/displaySuccessToast';
import {
  useSignDocumentMutation,
  useGetDocumentQuery,
} from '../../redux/services/missions';
import { Signature } from '../../globals';
import { useSelector } from 'react-redux';

const MissionDocument = () => {
  const [isSigning, setIsSigning] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const signatureRef = useRef<SignatureCanvas | null>(null);
  const [signDocument] = useSignDocumentMutation();
  const { id, documentId } = useParams();
  const { data: document, refetch } = useGetDocumentQuery({
    id: parseInt(id as string),
    documentId: parseInt(documentId as string),
  }) as any;

  const userId = useSelector((state: any) => state.user.user.id);

  const handleSign = () => {
    setIsLoading(true);
    if (signatureRef.current && signatureRef.current.isEmpty()) {
      displayWarningToast('Veuillez signer avant de soumettre.');
    } else if (signatureRef.current) {
      const signatureData = signatureRef.current.toDataURL();
      signDocument({
        body: {
          projectId: parseInt(id || ''),
          documentId: parseInt(documentId || ''),
          signature: signatureData,
        },
      })
        .unwrap()
        .then(() => {
          refetch();
          displaySuccessToast('Signature enregistrée avec succès.');
          setIsLoading(false);
        })
        .catch(() => {
          displayWarningToast('Une erreur est survenue.');
          setIsLoading(false);
        });
    }
  };

  const clearSignature = () => {
    if (signatureRef.current) {
      signatureRef.current.clear();
    }
  };

  const requireSignature = useMemo(() => {
    if (!document) {
      return false;
    }
    if (document.signatures?.length === 0) {
      return true;
    }
    return !document.signatures?.some(
      (signature: Signature) => signature.signerId === userId
    );
  }, [document]);

  return (
    <DashboardLayout>
      <h1 className="text-3xl font-bold text-gray-900">
        Document de la mission - {document?.name}
      </h1>
      <div className="mt-6 flex flex-col lg:flex-row gap-4">
        <PDFViewer file={document?.fileUrl} />
        {document?.requiredSignatures && (
          <div className="h-[90vh] w-full">
            <h2 className="text-2xl font-bold text-gray-900">Actions</h2>
            <div className="mt-6 bg-white rounded-md w-full h-full p-4">
              {requireSignature && (
                <div className="p-2 flex gap-2 flex-col w-full">
                  <p className="text-center font-bold italic">
                    Veuillez signer le document ci-dessous
                  </p>
                  <div className="border w-fit">
                    <SignatureCanvas
                      ref={signatureRef}
                      penColor="black"
                      canvasProps={{ width: 400, height: 200 }}
                      onBegin={() => setIsSigning(true)}
                      onEnd={() => setIsSigning(false)}
                    />
                  </div>
                  <div className="flex gap-2 justify-end">
                    <button
                      className="rounded-md p-2 bg-red-400 text-white font-bold"
                      onClick={clearSignature}
                      disabled={isLoading}
                    >
                      Effacer
                    </button>
                    <button
                      className="rounded-md p-2 bg-green-400 text-white font-bold"
                      onClick={handleSign}
                      disabled={isSigning || isLoading}
                    >
                      {isLoading && (
                        <ThreeDots
                          color="#FFF"
                          height={24}
                          width={24}
                          visible={isLoading}
                        />
                      )}
                      {!isLoading && <span>Signer</span>}
                    </button>
                  </div>
                </div>
              )}
              <div>
                <p className="text-center font-bold italic">Signatures</p>
                {!document?.requiredSignatures ? (
                  <p className="text-center italic my-4">
                    Ce document ne nécessite pas de signature.
                  </p>
                ) : (
                  <div>
                    {document?.signatures.length === 0 && (
                      <p className="text-center italic my-4">
                        Aucune signature n&apos;a été enregistrée pour ce
                        document.
                      </p>
                    )}
                    {!requireSignature && (
                      <p className="text-center text-green-500">
                        Vous avez déjà signé ce document.
                      </p>
                    )}
                    <div className="flex flex-col gap-2 mt-2">
                      {document?.signatures.map((signature: Signature) => (
                        <DocumentSignature
                          key={signature.id}
                          signature={signature}
                        />
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};

export default MissionDocument;

import React, { useState, ChangeEvent } from 'react';

interface EditableFieldProps {
  label: string;
  value: string;
  isEditMode: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
}

const EditableField: React.FC<EditableFieldProps> = ({
  label,
  value,
  isEditMode,
  onChange,
  placeholder,
}) => {
  return (
    <div className="w-1/2">
      <p className="text-blue-500">{label}</p>
      {isEditMode ? (
        <input
          className="font-bold rounded-md border border-black border-1"
          type="text"
          defaultValue={value}
          onChange={onChange}
          placeholder={placeholder}
        />
      ) : (
        <p className="font-bold">{value}</p>
      )}
    </div>
  );
};

export default EditableField;

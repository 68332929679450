import React, { useCallback, useEffect, useMemo, useState } from 'react'; // Added useState import
import { CiSettings } from 'react-icons/ci';
import { HiMagnifyingGlass } from 'react-icons/hi2';
import { useSelector } from 'react-redux';
import { ThreeDots } from 'react-loader-spinner';
import { FaUser } from 'react-icons/fa';
import { MdWork } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { BiChevronRight } from 'react-icons/bi';

import NotificationBell from './molecules/NotificationBell';
import DarkModeSwitcher from './molecules/Dark';

const FreelanceNavbar = () => {
  const [search, setSearch] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const navigate = useNavigate();

  const { freelances, projectUsers } = useSelector(
    (state: any) => state.user.user
  );

  const clients = freelances.map((freelance: any) => freelance.client);

  const projects = useMemo(() => {
    return projectUsers?.map((projectUser: any) => projectUser?.project);
  }, [projectUsers]);

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setLoading(true);
      setSearch(e.target.value);
      setSearchResults([]);

      if (e.target.value === '') {
        setLoading(false);
      } else {
        const clientResults = clients?.filter(
          (client: any) =>
            client?.first_name
              ?.toLowerCase()
              .includes(e.target.value.toLowerCase()) ||
            client?.last_name
              ?.toLowerCase()
              .includes(e.target.value.toLowerCase()) ||
            client?.email?.toLowerCase().includes(e.target.value.toLowerCase())
        );

        const projectResults = projects?.filter((project: any) =>
          project?.name?.toLowerCase().includes(e.target.value.toLowerCase())
        );

        const clientResultsWithType = clientResults.map((result: any) => ({
          ...result,
          type: 'client',
        }));
        const projectResultsWithType = projectResults.map((result: any) => ({
          ...result,
          type: 'project',
        }));

        const results = [...clientResultsWithType, ...projectResultsWithType];
        setSearchResults(results);
        setLoading(false);
      }
    },
    [clients, projects]
  );

  return (
    <nav className="flex gap-6 xl:gap-0 justify-end lg:justify-between items-center px-4 py-2 z-50 absolute top-0 w-full h-24 bg-gradient">
      <div className="flex-1 flex justify-end xl:justify-center">
        {/* <div className="md:w-1/2 sm:w-full max-[640px]:w-full max-w-2xl flex items-center border border-whiteGrey rounded-full px-4 py-2 bg-creamWhite text-mainBlack relative z-10">
          <input
            type="text"
            placeholder="Rechercher un client ou une mission"
            className="w-full outline-none bg-creamWhite"
            onChange={onChange}
            value={search}
          />
          {!!search && (
            <div className="absolute w-full h-46 top-12 left-0 bg-white rounded-md shadow-md p-4 z-50">
              {loading ? (
                <div className="w-full flex justify-center items-center">
                  <ThreeDots
                    visible={true}
                    height="50"
                    width="50"
                    ariaLabel=""
                    wrapperStyle={{}}
                    wrapperClass=""
                    color="#2850E7"
                  />
                </div>
              ) : (
                <div className="flex flex-col gap-2">
                  {searchResults.length > 0 ? (
                    searchResults.map((result, index) => (
                      <div
                        key={index}
                        onClick={() => {
                          if (result.type === 'client') {
                            navigate(`/client/${result.id}`);
                          } else if (result.type === 'project') {
                            navigate(`/mission/${result.id}`);
                          }
                        }}
                        className="flex justify-between items-center gap-4 rounded-md w-full hover:bg-primary hover:bg-opacity-40 p-2 cursor-pointer"
                      >
                        {result.type === 'client' && (
                          <div className="flex gap-2">
                            <FaUser
                              size={24}
                              color="white"
                              className="bg-gradient rounded-full p-2 w-8 h-8"
                            />
                            <div className="flex flex-col">
                              <p className="text-sm font-bold">
                                {result.first_name} {result.last_name}
                              </p>
                              <p className="text-xs">{result.email}</p>
                            </div>
                          </div>
                        )}
                        {result.type === 'project' && (
                          <div className="flex gap-2">
                            <MdWork
                              size={24}
                              color="white"
                              className="bg-gradient rounded-full p-2 w-8 h-8"
                            />
                            <div className="flex flex-col">
                              <p className="text-sm font-bold">{result.name}</p>
                              <div className="text-xs flex gap-1 items-center">
                                Statut:{' '}
                                <span>
                                  {result.status === 'in_progress'
                                    ? 'En cours'
                                    : result.status === 'completed'
                                    ? 'Terminé'
                                    : 'Annulé'}
                                </span>
                                <span
                                  className={`w-2 h-2 rounded-full block ${
                                    result.status === 'in_progress'
                                      ? 'bg-yellow-500'
                                      : result.status === 'completed'
                                      ? 'bg-green-500'
                                      : 'bg-red-500'
                                  }`}
                                ></span>
                              </div>
                            </div>
                          </div>
                        )}
                        <BiChevronRight size={24} color="black" />
                      </div>
                    ))
                  ) : (
                    <p className="text-sm text-gray-500">
                      Aucun résultat trouvé
                    </p>
                  )}
                </div>
              )}
            </div>
          )}
          <HiMagnifyingGlass className="text-mainBlack" />
        </div> */}
      </div>
      <div className="flex justify-end gap-4">
        <button className="rounded-full bg-whiteGrey hover:bg-lightGrey p-2">
          <CiSettings
            className="text-mainBlack"
            size={24}
            onClick={() => navigate('/settings/identity')}
          />
        </button>
        <NotificationBell />
        <DarkModeSwitcher />
      </div>
    </nav>
  );
};

export default FreelanceNavbar;

import React, { useState } from 'react';
import { IconType } from 'react-icons';
import { motion } from 'framer-motion';

type PricingCardProps = {
  title: string;
  description: string;
  price: string;
  offre: string;
  advantages: { text: string; icon: IconType }[];
  ctaLabel: string;
  ctaOnClick: () => void;
  color: string;
};

const PricingCard = ({
  title,
  description,
  price,
  offre,
  advantages,
  ctaLabel,
  ctaOnClick,
  color,
}: PricingCardProps) => {

  const listItemVariants = {
    hidden: { opacity: 0, y: -10 },
    visible: { opacity: 1, y: 0 },
  };

  const buttonVariants = {
    hidden: { opacity: 0, scale: 1 },
    visible: { opacity: 1, scale: 1 },
  };

  return (

    <div className="bg-white rounded-lg shadow-lg p-6 space-y-4 ml-10 mb-10 border border-gray-300 border-opacity-50 w-[400px]">
      <motion.h2
        className="text-4xl font-semibold mb-5 font-extrabold text-center "
        initial={{ opacity: 0, scale: 1 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.3, delay: 0.2 }}
        style={{ color }}
      >
        {title}
      </motion.h2>
      <motion.p
        className="text-black font-semibold mb-5 text-center"
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3, delay: 0.4 }}
      >
        {description}
      </motion.p>
      <div className="flex items-center justify-center mb-4">
        <motion.span
          className="text-6xl font-bold mr-2 text"
          initial={{ opacity: 0, scale: 1 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.3, delay: 0.6 }}
          style={{ color }}
        >
          {price}
        </motion.span>
        <span className="text-gray-600">/mission</span>
      </div>

      <motion.p
        className="mb-5 text-xs text-center"
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3, delay: 0.4 }}
        style={{ color }}
      >
        {offre}
      </motion.p>
      <ul className="mb-4">
        {advantages.map((advantage, index) => (
          <motion.li
            key={index}
            className="flex items-center font-semibold space-x-5 mt-8"
            variants={listItemVariants}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.3, delay: 0.2 + index * 0.1 }}
          >
            <span className="rounded-full w-10 h-10 flex items-center justify-center" style={{ backgroundColor: color }}>
              {advantage.icon({ size: 20, color: '#FFFFFF' })}
            </span>
            <span>{advantage.text}</span>
          </motion.li>
        ))}
      </ul>

      <motion.button
        className="bg-500 hover:bg-blue-600 text-white font-semibold rounded-xl mt-5 py-3 px-4 transition-colors duration-300 w-full"
        onClick={ctaOnClick}
        variants={buttonVariants}
        initial="hidden"
        animate="visible"
        transition={{ duration: 0.3, delay: 1 }}
        style={{ backgroundColor: color }}
      >
        {ctaLabel}
      </motion.button>
    </div>
  );
};



export default PricingCard;

import React from 'react';
import Button from '../Button';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import Spline from '@splinetool/react-spline';

const Hero = () => {
  const navigate = useNavigate();
  return (
    <section className="relative w-full h-screen mx-auto flex flex-wrap items-center my-20">
      <div className="w-full lg:w-1/2 xl:w-2/5 px-8 lg:px-16 xl:px-24">
        <h1 className="text-white text-4xl lg:text-5xl xl:text-6xl md:text-6xl md:my-20 sm:text-5xl sm:my-20 xs:text-5xl xs:my-20 font-bold mb-4">
          Votre activité freelance.
        </h1>
        <h1 className="text-white text-4xl lg:text-5xl xl:text-6xl md:text-6xl md:my-20 md:-mt-20 sm:text-5xl sm:my-20 xs:text-5xl xs:my-20 font-bold mb-4">
          Dans votre bulle.
        </h1>
        <h2 className="text-white text-base lg:text-lg xl:text-xl mb-6">
          Sécurisez vos prestations de services et échanges de fonds avec Luter.
        </h2>
        <ul className="text-white list-disc list-inside mb-6">
          <li>Aucun impayé ni retard de paiement</li>
          <li>Accompagnement personnalisé</li>
          <li>Résolution de litige</li>
        </ul>
        <Button
          label="Essayez gratuitement"
          primary
          onClick={() => navigate('/')}
        />
        <p className="text-white text-sm">
          Première mission offerte sous condition*
        </p>
      </div>
      <div className="w-full lg:w-1/2 xl:w-3/5 lg:visible xs:invisible h-full">
        {/* <video loop autoPlay muted className="lg:scale-100 md:m-0 md:scale-50 sm:-mx-10 sm:scale-25">
          <source src="src\assets\astronaut.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video> */}
        <Spline
          scene="https://prod.spline.design/3hcNuS3TzWwPjWAp/scene.splinecode"
          className="lg:scale-100 md:m-0 md:scale-50 sm:-mx-10 sm:scale-25"
        />
      </div>
      <div className="absolute xs:bottom-20 md:bottom-40 w-full flex justify-center items-center">
        <a href="http://localhost:5173#about">
          <div className="w-[35px] h-[64px] rounded-3xl border-4 border-secondary flex justify-center items-center">
            <motion.div
              animate={{
                y: [-15, 20, -15],
              }}
              transition={{
                duration: 1.5,
                repeat: Infinity,
                repeatType: 'loop',
              }}
              className="w-3 h-3 rounded-full bg-secondary mb-1"
            />
          </div>
        </a>
      </div>
    </section>
  );
};

export default Hero;

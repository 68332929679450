import React from 'react';
import { ThreeDots } from 'react-loader-spinner';

type Props = {
  primary?: boolean;
  size?: 'small' | 'medium' | 'large';
  label: string;
  onClick: any;
  loading?: boolean;
};

const Button = ({
  primary = false,
  size = 'medium',
  label,
  loading = false,
  ...props
}: Props) => {
  return (
    <button
      type="button"
      className={`
            ${
              primary
                ? 'bg-white hover:opacity-80 text-primary'
                : 'bg-transparent border-white border-2 hover:opacity-80 text-white'
            }
            font-bold py-2 px-4 transition-all rounded-lg min-w-fit
            ${
              size === 'small'
                ? 'text-sm'
                : size === 'large'
                ? 'text-lg'
                : 'text-base'
            }
        `}
      {...props}
    >
      {loading ? (
        <ThreeDots
          visible={true}
          height="30"
          width="30"
          ariaLabel=""
          wrapperStyle={{}}
          wrapperClass=""
          color="#2850E7"
        />
      ) : (
        label
      )}
    </button>
  );
};

export default Button;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import PricingCard from '../../Components/Landing/PricingCard';
import ComparisonTable from '../../Components/Landing/ComparisonTable';
// icons
import { BsPen } from 'react-icons/bs';
import { AiOutlineSafetyCertificate } from 'react-icons/ai';
import { SlNotebook } from 'react-icons/sl';
import { VscLaw } from 'react-icons/vsc';
import { MdOutlineRemoveRedEye } from 'react-icons/md';

const PricingSection = () => {
  const navigate = useNavigate();
  const handleButtonGuidance = () => {
    navigate('/guidance');
  };
  const handleCTAClick = () => {
    navigate('/register');
  };

  return (
    <section className="mx-6 xs:ml-0">
      <h1 className="font-bold text-4xl mb-4 mt-5 text-secondary text-center">
        Trouvez la <span className="text-primary">couverture</span> pensée pour
        vous.
      </h1>
      <div className="flex xs:flex-col xl:flex-row justify-center xs:ml-16 sm:ml-32 xl:ml-0">
        <PricingCard
          title="Green"
          description="Une formule d'entrée de gamme."
          price="2%"
          offre="1 mission offerte en optant pour l'offre annuelle"
          advantages={[
            { text: 'Génération de smart contracts', icon: BsPen },
            { text: 'Génération du cahier des charges', icon: SlNotebook },
            {
              text: 'Séquestration de fonds',
              icon: AiOutlineSafetyCertificate,
            },
          ]}
          ctaLabel="Essayer Luter Green"
          ctaOnClick={handleCTAClick}
          color="#1A4731"
        />
        <PricingCard
          title="Blue"
          description="Une formule adaptée aux besoins habituels."
          price="7%"
          offre="1 mission offerte en optant pour l'offre annuelle"
          advantages={[
            { text: 'Génération de smart contracts', icon: BsPen },
            { text: 'Génération du cahier des charges', icon: SlNotebook },
            {
              text: 'Séquestration de fonds',
              icon: AiOutlineSafetyCertificate,
            },
            { text: 'Gestion litiges < 5k€', icon: VscLaw },
            {
              text: 'Validation cahier des charges',
              icon: MdOutlineRemoveRedEye,
            },
          ]}
          ctaLabel="Essayer Luter Blue"
          ctaOnClick={handleCTAClick}
          color="#194D81"
        />
        <PricingCard
          title="Purple"
          description="Adaptée à certains besoins."
          price="11%"
          offre="2 missions offertes en optant pour l'offre annuelle"
          advantages={[
            { text: 'Génération de smart contracts', icon: BsPen },
            { text: 'Génération du cahier des charges', icon: SlNotebook },
            {
              text: 'Séquestration de fonds',
              icon: AiOutlineSafetyCertificate,
            },
            { text: 'Gestion litiges > 5k€', icon: VscLaw },
            {
              text: 'Validation cahier des charges',
              icon: MdOutlineRemoveRedEye,
            },
          ]}
          ctaLabel="Essayer Luter Purple"
          ctaOnClick={handleCTAClick}
          color="#563D7C"
        />
      </div>
      <ComparisonTable />
      <motion.div
        className="flex flex-col items-center mt-8"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.5 }}
      >
        <motion.h2
          className="text-3xl font-bold text-secondary"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          Besoin d&apos;orientation?
        </motion.h2>
        <motion.button
          className="px-6 py-3 mt-4 bg-primary text-white rounded-full focus:outline-none mb-5"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={handleButtonGuidance}
        >
          Remplir le formulaire
        </motion.button>
      </motion.div>
    </section>
  );
};

export default PricingSection;

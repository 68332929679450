import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// Components
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import PricingSection from '../Components/Landing/PricingSection';
// Icon

const Tarification: React.FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <Navbar isLogged={false} />
      <div className="flex flex-col justify-center items-center mt-5">
        <PricingSection />
      </div>
      <Footer />
    </>
  );
};

export default Tarification;

// WebSocketListener.js

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { onNewNotification } from '../redux/feature/userSlice';

import { socket } from './socket';

function WebSocketListener() {
  const dispatch = useDispatch();

  useEffect(() => {
    socket.on('notification', (notification) => {
      dispatch(onNewNotification(notification));
    });

    return () => {
      socket.off('notification');
    };
  }, [dispatch]);

  return null;
}

export default WebSocketListener;

import React, { useState, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
// Icon
import { MdEdit, MdSave } from 'react-icons/md';

// Components
import SettingsMenu from '../../Components/Dashboard/SettingsMenu';
import Button from '../../Components/Button';
import DashboardLayout from '../../Layout/DashboardLayout';
import SettingsMenuSmall from '../../Components/Dashboard/SettingsMenuSmall';
const SettingsPrivacy: React.FC = () => {
  const dummyProfiles = [
    {
      id: 1,
      firstname: 'John',
      lastname: 'Doe',
      fullname: 'John Doe',
      job: 'Développeur Web',
      city: 'Paris',
      pseudo: 'johndoe',
      pseudoTwitter: 'johndoeTwitter',
      pseudoGithub: 'johndoeGithub',
      pseudoLinkedin: 'johndoeLinkedin',
      pseudoFacebook: 'johndoeFacebook',
      email: 'johndoe@internet.com',
      phone: '+33 6 12 34 56 78',
      lastConnections: 'aujourd’hui à 18:34, Chrome 198.123.23.23',
      activeSessions: [
        ['DESKTOP-6TIG6EC • Tourcoing, France', 'Chrome • Actif en ce moment'],
        ['Iphone 11 • Tourcoing, France', 'Safari • 18/06/2023'],
        ['MAC-484284 • Paris, France', 'Safari • 07/04/2023'],
      ],
    },
  ];
  // useState
  const navigate = useNavigate();
  const [isEditMode, setIsEditMode] = useState(false);
  const [newEmail, setNewEmail] = useState(dummyProfiles[0].email);
  const [newPhone, setNewPhone] = useState(dummyProfiles[0].phone);
  const [newFullname, setNewFullname] = useState(dummyProfiles[0].fullname);
  const [enabled, setEnabled] = useState(false);
  const nombreDeSessionsActives = dummyProfiles[0].activeSessions.length;
  // Dumb handle onClick
  const handleMailChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setNewEmail(e.target.value);
  };
  const handlePhoneChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setNewPhone(e.target.value);
  };
  const handleEditClick = () => {
    setIsEditMode(true);
  };
  const handleSaveClick = () => {
    // implémenter ici la logique de sauvegarde des données modifiées
    setIsEditMode(false);
  };

  return (
    <DashboardLayout>
      <div className="flex">
        <div className="w-full p-4">
          <div className="flex flex-row mb-10">
            <h1 className="text-left text-4xl font-bold bold-4 w-1/2 text-left">
              Paramètres du profil
            </h1>
            <div className="w-1/2 text-right">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                onClick={() => navigate('/profile')}
              >
                Voir votre profil publique
              </button>
            </div>
          </div>
          <div className="md:hidden xs:block w-1/4 pr-4">
            <SettingsMenuSmall />
          </div>
          <div className="flex h-full overflow-y-auto shadow-2xl bg-creamWhite rounded-[30px] p-6 xs:-mr-8 md:mr-0">
            <div className="md:block xs:hidden w-1/4 pr-4">
              <SettingsMenu />
            </div>
            <div className="xs:hidden md:block w-px bg-gray-300 mx-4" />

            <div className="w-full overflow-y-auto border-2 bg-creamWhite rounded-[5px] p-4">
              <div className="flex items-center justify-between">
                <h1 className="font-bold text-3xl mt-4">Confidentialité</h1>
              </div>
              <h3 className="text-cyan-900 ml-5">
                Nous gardons vos données privées et ne les partageons pas avec
                des parties tier
              </h3>
              <hr className="my-2" />
              <div className="flex mt-10">
                <div className="space-y-5 text-left mx-20">
                  <label className="inline-flex relative items-center cursor-pointer">
                    <input
                      type="checkbox"
                      className="sr-only peer"
                      checked={enabled}
                      readOnly
                    />
                    <div
                      onClick={() => {
                        setEnabled(!enabled);
                      }}
                      className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-blue-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"
                    ></div>
                    <p className="text-cyan-900 font-bold ml-3">
                      Apparaître dans les moteurs de recherche
                    </p>
                  </label>
                  <p className="text-cyan-800 text-sm">
                    Si vous décochez cette case, votre profil risque de perdre
                    en visibilité sur la plateforme. En effet, cela entraînera
                    une invisibilité de votre profil sur les moteurs de
                    recherche tels que Google, Bing, et sur le moteur de
                    recherche interne de Luter pour les utilisateurs non
                    connectés.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default SettingsPrivacy;

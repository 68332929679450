import React, { useState, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
// Icon
import { MdEdit, MdSave } from 'react-icons/md';

// Components
import SettingsMenu from '../../Components/Dashboard/SettingsMenu';
import Button from '../../Components/Button';
import DashboardLayout from '../../Layout/DashboardLayout';
import SettingsMenuSmall from '../../Components/Dashboard/SettingsMenuSmall';

const SettingsNotif: React.FC = () => {
  // useState
  const navigate = useNavigate();
  const [enabled, setEnabled] = useState(false);
  const [enabled2, setEnabled2] = useState(false);
  const [enabled3, setEnabled3] = useState(false);
  const [enabled4, setEnabled4] = useState(false);

  return (
    <DashboardLayout>
      <div className="flex">
        <div className="w-full p-4">
          <div className="flex flex-row mb-10">
            <h1 className="text-left text-4xl font-bold bold-4 w-1/2 text-left">
              Paramètres du profil
            </h1>
            <div className="w-1/2 text-right">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                onClick={() => navigate('/profile')}
              >
                Voir votre profil publique
              </button>
            </div>
          </div>
          <div className="md:hidden xs:block w-1/4 pr-4">
            <SettingsMenuSmall />
          </div>
          <div className="flex h-full overflow-y-auto shadow-2xl bg-creamWhite rounded-[30px] p-6 xs:-mr-8 md:mr-0">
            <div className="md:block xs:hidden w-1/4 pr-4">
              <SettingsMenu />
            </div>
            <div className="xs:hidden md:block w-px bg-gray-300 mx-4" />

            <div className="w-full">
              {/* Section 1 */}
              <div className="w-full overflow-y-auto border-2 bg-creamWhite rounded-[5px] p-4 mb-5">
                <div className="flex items-center justify-between">
                  <h1 className="font-bold text-3xl mt-4">Messagerie</h1>
                </div>
                <h3 className="text-cyan-900 ml-5">
                  Restez informé(e) des messages relatifs à vos missions grâce à
                  nos notifications.
                </h3>
                <hr className="my-2" />
                <div className="flex flex-row mt-10 mx-20">
                  <div className="flex flex-col">
                    <label className="inline-flex relative items-center cursor-pointer mb-5">
                      <input
                        type="checkbox"
                        className="sr-only peer"
                        checked={enabled}
                        readOnly
                      />
                      <div
                        onClick={() => {
                          setEnabled(!enabled);
                        }}
                        className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-blue-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"
                      ></div>
                      <label className="text-cyan-900 font-bold ml-3">
                        Recevoir un SMS lorsqu’on m’envoie un message
                      </label>
                    </label>

                    <label className="inline-flex relative items-center cursor-pointer">
                      <input
                        type="checkbox"
                        className="sr-only peer"
                        checked={enabled2}
                        readOnly
                      />
                      <div
                        onClick={() => {
                          setEnabled2(!enabled2);
                        }}
                        className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-blue-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"
                      ></div>
                      <label className="text-cyan-900 font-bold ml-3">
                        Recevoir un email lorsqu’on m’envoie un message
                      </label>
                    </label>
                  </div>
                </div>
              </div>

              {/* Section 2 */}
              <div className="w-full overflow-y-auto border-2 bg-creamWhite rounded-[5px] p-4 mb-5">
                <div className="flex items-center justify-between">
                  <h1 className="font-bold text-3xl mt-4">Activité</h1>
                </div>
                <h3 className="text-cyan-900 ml-5">
                  Restez informé(e) des activités grâce à nos notifications.
                </h3>
                <hr className="my-2" />
                <div className="flex flex-row mt-10 mx-20">
                  <div className="flex flex-col">
                    <label className="inline-flex relative items-center cursor-pointer mb-5">
                      <input
                        type="checkbox"
                        className="sr-only peer"
                        checked={enabled3}
                        readOnly
                      />
                      <div
                        onClick={() => {
                          setEnabled3(!enabled3);
                        }}
                        className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-blue-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"
                      ></div>
                      <label className="text-cyan-900 font-bold ml-3">
                        Recevoir un résumé de mes activités par email
                      </label>
                    </label>

                    <label className="inline-flex relative items-center cursor-pointer">
                      <input
                        type="checkbox"
                        className="sr-only peer"
                        checked={enabled4}
                        readOnly
                      />
                      <div
                        onClick={() => {
                          setEnabled4(!enabled4);
                        }}
                        className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-blue-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"
                      ></div>
                      <label className="text-cyan-900 font-bold ml-3">
                        Recevoir des informations concernant les nouveautés de
                        Luter
                      </label>
                    </label>
                  </div>
                </div>
              </div>

              {/* Section 3 */}
              <div className="w-full overflow-y-auto border-2 bg-creamWhite rounded-[5px] p-4 mb-5">
                <div className="flex items-center justify-between">
                  <h1 className="font-bold text-3xl mt-4">
                    Notifications obligatoires
                  </h1>
                </div>
                <h3 className="text-cyan-900 ml-5">
                  Ces options de communication ne peuvent pas être désactivées,
                  elles sont indispensables pour assurer le bon fonctionnement
                  du service.
                </h3>
                <hr className="my-2" />
                <div className="flex flex-row mt-10 mx-20">
                  <div className="flex flex-col">
                    <label className="inline-flex relative items-center cursor-pointer mb-5">
                      <input
                        type="checkbox"
                        className="sr-only peer"
                        readOnly
                      />
                      <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-blue-300 after:content-[''] after:absolute after:top-0.5 after:left-[22px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5"></div>
                      <label className="text-cyan-900 font-bold ml-3">
                        Recevoir les emails concernant les modifications des CGU
                      </label>
                    </label>

                    <label className="inline-flex relative items-center cursor-pointer">
                      <input
                        type="checkbox"
                        className="sr-only peer"
                        readOnly
                      />
                      <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-blue-300 after:content-[''] after:absolute after:top-0.5 after:left-[22px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5"></div>
                      <label className="text-cyan-900 font-bold ml-3">
                        Recevoir tout email important concernant votre compte
                        (sécurité, informations diverses...)
                      </label>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default SettingsNotif;

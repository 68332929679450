import React from 'react';
import white_logo from '../assets/white_logo.png';

const HeaderMission = (props: { text: string | undefined }) => {
  return (
    <nav className="flex xl:gap-0 justify-between lg:justify-between items-center px-4 z-0 top-0 w-full h-24 bg-gradient">
      <div className="flex-1 flex justify-center items-center text-white text-4xl font-bold">
        {props.text}
        <div className="flex absolute right-5 font-normal">
          <img src={white_logo} alt="white_logo" className="w-10 h-auto" />
          <p className="text-white text-4xl pl-2">luter</p>
        </div>
      </div>
    </nav>
  );
};

export default HeaderMission;

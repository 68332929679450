import React, { useState, useEffect } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import LogoWhite from '../assets/logo_white_text_white.svg';
import LogoBlack from '../assets/logo_blue_text_black.svg';
import ResponsiveNavbar from './NavbarResponsive';
import DropDownMenu from './NavbarDropDownMenu';

type NavbarProps = {
  isLogged: boolean;
  isFixed?: boolean;
};

const Navbar = ({ isLogged, isFixed = true }: NavbarProps) => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [expandedLabel, setExpandedLabel] = useState<string | null>(null);
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(
    window.innerWidth < 1024
  );

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1024);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleMenuClick = (label: string) => {
    if (expandedLabel === label) {
      setExpandedLabel(null);
    } else {
      setExpandedLabel(label);
    }
    setIsMenuOpen(false);
  };

  return (
    <>
      {!isSmallScreen && (
        <motion.nav
          className="fixed top-0 left-0 w-full z-50"
          // className={`${isFixed ? 'fixed top-0 left-0' : ''} w-full z-50`}
          initial={{ backgroundColor: 'transparent' }}
          animate={{ backgroundColor: expandedLabel ? '#FFFFFF' : '#1552F0' }}
          transition={{ duration: 0.3 }}
        >
          <div className="container mx-auto lg:px-8 xl:px-32 py-4 flex justify-between items-center">
            <a href="/">
              <motion.img
                src={expandedLabel ? LogoBlack : LogoWhite}
                alt="logo"
                className="w-32"
                transition={{ duration: 0.3 }}
              />
            </a>
            <div className="hidden lg:flex items-center space-x-4">
              <DropDownMenu
                label="Personnel"
                expandedLabel={expandedLabel}
                handleMenuClick={handleMenuClick}
              />
              <DropDownMenu
                label="Formules"
                expandedLabel={expandedLabel}
                handleMenuClick={handleMenuClick}
              />
              <DropDownMenu
                label="À propos"
                expandedLabel={expandedLabel}
                handleMenuClick={handleMenuClick}
              />
            </div>
            <div
              className={`${
                isMenuOpen ? 'flex' : 'hidden'
              } lg:hidden flex-col w-full pt-4 pb-2 px-4 ${
                expandedLabel ? 'bg-white' : 'bg-blue-300 bg-opacity-50'
              }`}
            ></div>
            <div className="hidden lg:flex items-center space-x-4">
              <button
                className={`text-md px-4 font-semibold py-2 rounded-md focus:outline-none hover:opacity-80 transition-all ${
                  expandedLabel
                    ? 'text-black hover:text-gray-800'
                    : 'text-white'
                }`}
                onClick={() => navigate('/login')}
                data-testid="sign-in-button"
              >
                Se connecter
              </button>
              <button
                className={`text-md border-2 hover:opacity-80 font-semibold py-2 px-4 transition-all rounded-lg min-w-fit ${
                  expandedLabel
                    ? 'text-black border-black hover:text-gray-800'
                    : 'text-white border-white'
                }`}
                onClick={() => navigate('/register')}
                data-testid="sign-up-button"
              >
                Créer un compte
              </button>
            </div>
          </div>
        </motion.nav>
      )}
      {isSmallScreen && <ResponsiveNavbar />}
    </>
  );
};

export default Navbar;

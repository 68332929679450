import React from 'react';
import DashboardLayout from '../Layout/DashboardLayout';
import { Link } from 'react-router-dom';
import { FaExternalLinkAlt } from 'react-icons/fa';

type Props = {};

const DeclarationScreen = (props: Props) => {
  return (
    <DashboardLayout>
      <div className="shadow-2xl bg-creamWhite rounded-[30px] min-h-[79vh] p-6 flex flex-col gap-4 border border-whiteGrey">
        <h1 className="text-3xl font-bold text-mainBlack">Déclaration</h1>
        <h2 className="text-2xl font-bold text-mainBlack mt-8">
          Simulateur de revenus
        </h2>
        <p className="text-mainBlack">
          Afin de simplifier la gestion de votre budget vous pouvez utiliser
          notre outil de simulation de revenu.
        </p>
        <div className="bg-fullWhite rounded-lg p-4">
          <Link
            to={'/declaration/simulation'}
            className="underline text-primary flex items-center gap-2"
          >
            Simuler mes revenus <FaExternalLinkAlt />
          </Link>
        </div>
        <h2 className="text-2xl font-bold text-mainBlack mt-8">
          Déclarer ses revenus
        </h2>
        <p className=" text-mainBlack">
          Vous pouvez déclarer vos revenus directement sur notre plateforme via
          le formulaire suivant.
        </p>
        <div className="bg-fullWhite rounded-lg p-4">
          <Link
            to={'/declaration/urssaf'}
            className="underline text-primary flex items-center gap-2"
          >
            Déclarer mes revenus sur Luter <FaExternalLinkAlt />
          </Link>
        </div>
        <p className="text-sm text-gray-400">
          Vous pouvez aussi déclarer vos revenus directement sur le{' '}
          <a
            href="https://www.autoentrepreneur.urssaf.fr/portail/accueil/une-question/toutes-les-fiches-pratiques/declarer-et-payer-mes-cotisation.html"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 underline"
          >
            site de l&apos;URSSAF
          </a>
          .
        </p>
      </div>
    </DashboardLayout>
  );
};

export default DeclarationScreen;

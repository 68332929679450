import React from 'react';
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import { BiChevronUp, BiChevronDown } from 'react-icons/bi';

type DropDownMenuProps = {
  label: string;
  handleMenuClick: (label: string) => void;
  expandedLabel: string | null;
};

const DropDownMenu: React.FC<DropDownMenuProps> = ({
  label,
  expandedLabel,
  handleMenuClick,
}) => {
  const isExpanded = label === expandedLabel;
  const isAnyLabelExpanded = expandedLabel !== null;
  let navLinks: JSX.Element[] = [];
  const navLinkClassName =
    'text-md text-gray-700 hover:text-gray-900 font-semibold hover:bg-gray-100 p-2 rounded-3xl transition duration-500';
  switch (label) {
    case 'Personnel':
      navLinks = [
        <NavLink key="Commencer" to="/commencer" className={navLinkClassName}>
          Commencer
        </NavLink>,
        <NavLink key="Avantages" to="/avantages" className={navLinkClassName}>
          Avantages
        </NavLink>,
        <NavLink key="API" to="/api" className={navLinkClassName}>
          API
        </NavLink>,
        <NavLink key="Crypto" to="/crypto" className={navLinkClassName}>
          Crypto
        </NavLink>,
      ];
      break;
    case 'Formules':
      navLinks = [
        <NavLink key="Offres" to="/tarification" className={navLinkClassName}>
          Offres
        </NavLink>,
        <NavLink
          key="Formulaire d'orientation"
          to="/guidance"
          className={navLinkClassName}
        >
          Orientation
        </NavLink>,
      ];
      break;
    case 'À propos':
      navLinks = [
        <NavLink key="Communaute" to="/communaute" className={navLinkClassName}>
          Code de conduite
        </NavLink>,
        <NavLink key="Contact" to="/contact" className={navLinkClassName}>
          Service client
        </NavLink>,
      ];
      break;
    default:
      break;
  }

  return (
    <div>
      <div key={label}>
        <span
          className="text-white flex justify-between items-center cursor-pointer"
          onClick={() => handleMenuClick(label)}
        >
          <motion.p
            className={'text-md font-medium'}
            animate={{
              color: isExpanded || isAnyLabelExpanded ? '#000000' : '#FFFFFF',
            }}
            transition={{ duration: 0.3 }}
          >
            {label}
          </motion.p>
          <span className={'hover:rotate-90 transition-all duration-300'}>
            {isExpanded ? (
              <BiChevronUp size={26} style={{ color: 'black' }} />
            ) : (
              <BiChevronDown size={26} style={{ color: 'white' }} />
            )}
          </span>
        </span>
        {isExpanded && (
          <div
            style={{
              borderBottom: '1px solid #ccc',
              borderTop: '1px solid #ccc',
            }}
            className="absolute bg-white w-full left-0 top-full z-50 grid grid-cols-2 lg:grid-cols-4 gap-4 py-2 px-64"
          >
            {navLinks}
          </div>
        )}
      </div>
    </div>
  );
};

export default DropDownMenu;

import React, { useCallback, useState } from 'react';
import ChatbotInput from './ChatbotInput';
import ChatbotFeed from './ChatbotFeed';
import { useHandleNewMessageMutation } from '../../redux/services/chatbot';

export const ChatbotChat = (props: ChatbotChatProps) => {
  const [chatHistory, setChatHistory] = useState([]) as any[];
  const [loading, setLoading] = useState(false);
  const [handleNewMessage] = useHandleNewMessageMutation();

  const addMessage = useCallback(
    async (role: any, content: any) => {
      if (content === '') return;

      setLoading(true);
      const newMessage = { role, content };

      const updatedChatHistory = [...chatHistory, newMessage];
      setChatHistory(updatedChatHistory);
      try {
        const response: any = await handleNewMessage({
          body: { chatHistory: updatedChatHistory },
        });

        if (response?.data) {
          setChatHistory([...updatedChatHistory, response.data]);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [chatHistory, handleNewMessage]
  );

  return (
    <div className="h-full flex flex-col w-full">
      <ChatbotFeed chatHistory={chatHistory} loading={loading} />
      <ChatbotInput addMessage={addMessage} />
    </div>
  );
};

export interface ChatbotChatProps {}

export default ChatbotChat;

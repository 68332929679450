import React from 'react';

interface TableProps {
  columns: Array<string>;
  data: Array<any>;
  checkbox?: boolean;
}

const Table = ({ columns, data, checkbox = false }: TableProps) => {
  return (
    <div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-mainGrey ">
          <thead className="text-xs text-mainGrey uppercase bg-whiteGrey ">
            <tr>
              {checkbox && (
                <th scope="col" className="p-4">
                  <div className="flex items-center">
                    <input
                      id="checkbox-all-search"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                    />
                    <label className="sr-only">checkbox</label>
                  </div>
                </th>
              )}
              {columns.map((column, index) => (
                <th key={column} scope="col" className="px-6 py-3">
                  {column}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.length > 0 ? (
              data.map((item, x) => (
                <tr
                  key={x}
                  className="bg-fullWhite border-b  hover:bg-gray-50 "
                >
                  {checkbox && (
                    <td className="w-4 p-4">
                      <div className="flex items-center">
                        <input
                          id="checkbox-table-search-1"
                          type="checkbox"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500  focus:ring-2"
                        />
                        <label className="sr-only">checkbox</label>
                      </div>
                    </td>
                  )}
                  {item.row.map((value: any, index: number) =>
                    index === 0 ? (
                      <th
                        key={index}
                        scope="row"
                        className="px-6 py-4 font-medium text-mainBlack whitespace-nowrap"
                      >
                        {value}
                      </th>
                    ) : (
                      <td key={index} className="px-6 py-4">
                        {value}
                      </td>
                    )
                  )}
                  <td className="px-6 py-4 flex gap-2">
                    {item.actions.map((action: any, index: number) => (
                      <button
                        key={index}
                        onClick={action.onClick}
                        className="font-medium text-primary hover:opacity-80 transition-all duration-150"
                      >
                        {action.label}
                      </button>
                    ))}
                  </td>
                </tr>
              ))
            ) : (
              <tr className="bg-fullWhite border-b hover:bg-gray-50 ">
                <td
                  className="px-6 py-4 text-center"
                  colSpan={columns.length + 1}
                >
                  Aucune donnée disponible
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;

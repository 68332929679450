import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface FormState {
  price: string;
  duration: string;
  tarificationHonnete: string;
  delaisCohérents: string;
  confiance: string;
  missions: string;
  techno: string;
  clientTravail: string;
  clientParticulier: string;
  clientLuter: string;
}

const initialState: FormState = {
  price: '0',
  duration: '0',
  tarificationHonnete: '',
  delaisCohérents: '',
  confiance: '',
  missions: '',
  techno: '',
  clientTravail: '',
  clientParticulier: '',
  clientLuter: '',
};

const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    updatePrice: (state, action: PayloadAction<string>) => {
      console.log('slice price: ', state.price);
      state.price = action.payload;
    },
    updateDuration: (state, action: PayloadAction<string>) => {
      state.duration = action.payload;
    },
    updateTarification: (state, action: PayloadAction<string>) => {
      state.tarificationHonnete = action.payload;
    },
    updateDeadline: (state, action: PayloadAction<string>) => {
      state.delaisCohérents = action.payload;
    },
    updateConfidence: (state, action: PayloadAction<string>) => {
      state.confiance = action.payload;
    },
    updateMissions: (state, action: PayloadAction<string>) => {
      state.missions = action.payload;
    },
    updateTechno: (state, action: PayloadAction<string>) => {
      state.techno = action.payload;
    },
    updateClientWork: (state, action: PayloadAction<string>) => {
      state.clientTravail = action.payload;
    },
    updateClientParticular: (state, action: PayloadAction<string>) => {
      state.clientParticulier = action.payload;
    },
    updateClientLuter: (state, action: PayloadAction<string>) => {
      state.clientLuter = action.payload;
    },
  },
});

export const {
  updatePrice,
  updateDuration,
  updateTarification,
  updateDeadline,
  updateConfidence,
  updateMissions,
  updateTechno,
  updateClientWork,
  updateClientParticular,
  updateClientLuter,
} = formSlice.actions;

export type FormStateType = ReturnType<typeof formSlice.reducer>;

export default formSlice.reducer;
